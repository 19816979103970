import React, { useState, useEffect, useRef } from "react";
import TableViewRoundedIcon from "@mui/icons-material/TableViewRounded";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import ReactApexChart from "react-apexcharts";
import { Rnd } from "react-rnd";
import { useSelector } from "react-redux";
import { getDate } from "../../Utils/OtherUtils";
import { DynamoDBApis } from "../../Services/ThirdPartyApi/AWS/DynamoDB";

const convertDDMMYYToDate = (ddmmyy) => {
  const [day, month, year] = ddmmyy.split("_"); // Split DD_MM_YY into day, month, and year
  return new Date(`20${year}`, month - 1, day); // Assuming the year is 20YY
};

export const AnnotationReport = ({
  onClose,
  elementWidth,
  selectedAsset,
  selectedCategory,
  selectedSeverity,
}) => {
  const [grpView, setGrpView] = React.useState(false);
  const [tableView, setTableView] = React.useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [assetFilter, setAssetFilter] = useState(selectedAsset);
  const [categoryFilter, setCategoryFilter] = useState(selectedCategory);
  const [severityFilter, setSeverityFilter] = useState(selectedSeverity);
  const groupData = useSelector((state) => state.gisHome.grpData);

  var reportvalue = parseInt(elementWidth) + 92;

  const predefinedCategories = [
    "All",
    "Garbage",
    "Debris",
    "Stock Pile",
    "Steel Scrap",
    "Other",
  ];
  const severities = ["All", "Low", "Medium", "High"];

  const styles = {
    calfordrgg: {
      width: `calc(100% - ${reportvalue}px)`,
      marginLeft: `${reportvalue}px`,
    },
  };

  const dataJson = {
    Reportee: "Aman Mishra",
    Supervisor: "Anand Mehta",
    "Reported on": "12-02-2022",
    "Date of Acknowledgement": "12-02-2022",
    "Date of Action": "16-02-2022",
    "Unit of measurement": "Tonne",
    Data: [
      {
        Date: "07-02-2022",
        "Waste Generated (Tonne)": 1240,
        "Waste Collected (Tonne)": 760,
        "% Gap in Cleaning": 63.16,
        "Time to Clean (Hours)": 36,
        "% Recyclable": 7.45,
        "% Reusable": 2.76,
      },
    ],
  };

  const assetIdToDate = {};
  groupData.forEach((item) => {
    assetIdToDate[item.id] = convertDDMMYYToDate(item?.date);
  });

  const groupedData = {};
  filteredData.forEach((item) => {
    if (!groupedData[item.category]) {
      groupedData[item.category] = [];
    }
    groupedData[item.category].push({
      x: assetIdToDate[item.assetId],
      y: item.volume,
    });
  });

  const series = Object.keys(groupedData).map((category) => ({
    name: category,
    data: groupedData[category].sort((a, b) => a.x - b.x),
  }));

  const options = {
    chart: {
      type: "line",
    },
    dataLabels: {
      enabled: true,
    },

    xaxis: {
      type: "datetime",
      title: {
        text: "Timeline",
      },
    },
    yaxis: {
      title: {
        text: "Volume in m³",
      },
      tickAmount: 10,
      min: 0,
      max: 150,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  const handleTableView = () => {
    setTableView(true);
    setGrpView(false);
  };
  const handleGrpView = () => {
    setGrpView(true);
    setTableView(false);
  };

  const findTimeline = (assetId) => {
    const timeline = groupData.filter((item) => item.id === parseInt(assetId));

    var date;
    if (timeline[0]) {
      if (timeline[0].date) {
        date = getDate(timeline[0]?.date);
      } else {
        date = "-";
      }
    } else {
      date = "-";
    }
    return date;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const retrievedData = await DynamoDBApis.getDataByFilter(
          categoryFilter,
          severityFilter,
          assetFilter ? parseInt(assetFilter) : null
        );
        // console.log(retrievedData, "retrivedData");
        setFilteredData(retrievedData);
        // Handle the data as needed
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };
    fetchData();
  }, [assetFilter, categoryFilter, severityFilter]);

  return (
    <div
      style={styles.calfordrgg}
      className="fixed inset-0 flex justify-center items-center  bg-opacity-60 z-50"
    >
      <Rnd
        default={{
          x: 100,
          y: 100,
          width: 700,
          height: 600,
        }}
        minWidth={500}
        minHeight={500}
        bounds="parent"
        className="resizable-box"
      >
        <div
          className="bg-white shadow-lg modal-content rounded-[12px] w-[100%] h-[100%] "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="header w-full rounded-tl-[12px] rounded-tr-[12px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500] relative">
            <span className="grow flex items-center justify-center">
              Annotation Report
            </span>
            <button
              className="absolute top-2 right-2 p-2 rounded-full  hover:bg-[#0000000a] text-[#b5b4b2]  focus:outline-none"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex gap-2 p-2 my-2 mx-5 items-center">
            <div className="flex flex-col w-full">
              <label className="text-sm">Timeline</label>
              <select
                className="border border-gray-400 rounded text-xs min-h-[1.5rem] "
                value={assetFilter}
                onChange={(e) =>
                  e.target.value === "All"
                    ? setAssetFilter(null)
                    : setAssetFilter(parseInt(e.target.value))
                }
              >
                <option value={"All"}>All</option>
                {groupData.map((groupData, index) => (
                  <option key={index} value={groupData.id}>
                    {getDate(groupData?.date)}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm">Category</label>
              <select
                className="border border-gray-400 rounded text-xs min-h-[1.5rem] "
                value={categoryFilter}
                onChange={(e) =>
                  e.target.value === "All"
                    ? setCategoryFilter(null)
                    : setCategoryFilter(e.target.value)
                }
              >
                {predefinedCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm">Risk </label>
              <select
                className="border border-gray-400 rounded text-xs m-h-[1.5rem]"
                value={severityFilter}
                onChange={(e) => {
                  e.target.value === "All"
                    ? setSeverityFilter(null)
                    : setSeverityFilter(e.target.value);
                }}
              >
                {severities.map((severity, index) => (
                  <option key={index} value={severity}>
                    {severity}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="report-info mb-4 p-1 text-left border rounded mx-4">
            <div className="flex flex-row justify-between items-center">
              <p className="text-lg font-semibold mb-2">Report Information</p>
              {/* <div className="flex flex-row">
                <span className="font-[500] hover:text-[#acacac] cursor-pointer">
                  Send Mail
                </span>
                <span className="w-[1px]  bg-[#e5e7eb] mr-2 ml-2 "></span>
                <span className="font-[500] hover:text-[#acacac] cursor-pointer">
                  Download
                </span>
              </div> */}
            </div>
            <div className="grow flex items-center gap-1 w-full">
              <div className="flex flex-col border-[1px] border-solid border-[#e5e7eb] p-2 w-[50%]">
                <div className="font-[500] text-[#acacac] text-[13px]">
                  Reportee :
                </div>
                <span className="font-[500]">{dataJson.Reportee}</span>
              </div>
              <div className="flex flex-col border-[1px] border-solid border-[#e5e7eb] p-2 w-[50%]">
                <div className="font-[500] text-[#acacac] text-[13px]">
                  Supervisor :
                </div>
                <span className="font-[500]">{dataJson.Supervisor}</span>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end pr-4 gap-2">
            <div
              onClick={handleTableView}
              className={`border-solid border-[1px] cursor-pointer transition rounded-[3px] p-1 ${
                tableView
                  ? "-translate-y-1 scale-100 border-[#4a4844]"
                  : "hover:-translate-y-1 hover:scale-100 hover:border-[#4a4844]"
              }`}
            >
              <TableViewRoundedIcon />
            </div>
            <div
              onClick={handleGrpView}
              className={`border-solid border-[1px] cursor-pointer transition rounded-[3px] p-1 ${
                grpView
                  ? "-translate-y-1 scale-100 border-[#4a4844]"
                  : "hover:-translate-y-1 hover:scale-100 hover:border-[#4a4844]"
              }`}
            >
              <EqualizerRoundedIcon />
            </div>
          </div>
          <div
            className={`h-[calc(100%-340px)] cursor-default m-4 overflow-y-auto overflow-x-auto custom-scrollbar ${
              tableView ? "block" : "hidden"
            }`}
          >
            <table className="table w-full border-[1px] border-solid border-[#e5e7eb]">
              <thead className="bg-[#4a4844] text-[#fff] sticky top-0">
                <tr>
                  <th className="px-2 py-3 font-[500]">Sr No</th>
                  <th className="px-2 py-3 font-[500]">Timeline</th>
                  <th className="px-2 py-3 font-[500]">Name</th>
                  <th className="px-2 py-3 font-[500]">Description</th>
                  <th className="px-2 py-3 font-[500]">Category</th>
                  <th className="px-2 py-3 font-[500]">Severity</th>
                  <th className="px-2 py-3 font-[500]">Area</th>
                  <th className="px-2 py-3 font-[500]">Volume</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {filteredData?.length &&
                  filteredData?.map((filteredData, index) => (
                    <tr key={index}>
                      <td className="px-2 py-3">{index + 1}</td>
                      <td className="px-2 py-3">
                        {findTimeline(filteredData?.assetId)}
                      </td>
                      <td className="px-2 py-3">{filteredData?.markerName}</td>
                      <td className="px-2 py-3">{filteredData?.description}</td>
                      <td className="px-2 py-3">{filteredData?.category}</td>
                      <td className="px-2 py-3">{filteredData?.severity}</td>
                      <td className="px-2 py-3">
                        {filteredData?.shapeData
                          ? parseFloat(filteredData?.shapeData?.area).toFixed(2)
                          : "-"}
                      </td>
                      <td className="px-2 py-3">
                        {filteredData?.volume
                          ? `${filteredData?.volume} m³`
                          : "-"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div
            className={`h-[calc(100%-340px)] m-4  ${
              grpView ? "block" : "hidden"
            }`}
          >
            <div id="chart" className="h-[100%]">
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
      </Rnd>
    </div>
  );
};
