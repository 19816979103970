import { useEffect } from "react";
import {
  Color,
  CallbackProperty,
  HeightReference,
  PolygonHierarchy,
  defined,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  LabelStyle,
  VerticalOrigin,
  HorizontalOrigin,
  Cartographic,
  Matrix4,
  ColorMaterialProperty,
  Math as MathOfCesium,
  Cartesian2,
  Cartesian3,
  NearFarScalar,
} from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";

export function usePolylineAnnotationTool(
  viewerRef,
  setShapeCords,
  setShapeData,
  setOpenPolylineAnnotationModal,
  setShapeCordLatLan
) {
  const dispatch = useDispatch();

  const { polylineAnnotation } = useSelector((state) => state.gisTools);

  useEffect(() => {
    const viewer = viewerRef.current;
    let drawingMode = "polyline";
    let activeShapePoints = [];
    let activeShape;
    let floatingPoint;
    let lineDistance = 0;
    let totalPolylineDistance = 0;
    let lastPointLabelEntity;
    let lineSegmentLabels = [];
    let point;

    function createPoint(worldPosition) {
      point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Color.GOLD,
          pixelSize: 10,
          heightReference: HeightReference.RELATIVE_TO_GROUND,
        },
      });
      return point;
    }

    function drawShape(positionData) {
      let shape;

      if (drawingMode === "polyline") {
        shape = viewer.entities.add({
          polyline: {
            positions: positionData,
            clampToGround: true,
            width: 3,
          },
        });
      }

      setShapeCords(positionData);

      const shapeLatLon =
        positionData?.length > 0
          ? positionData?.map((item) => {
              console.log(item.x, "item.x");
              let cartographic = Cartographic.fromCartesian(item);

              let lat = MathOfCesium.toDegrees(cartographic.latitude);
              let lon = MathOfCesium.toDegrees(cartographic.longitude);

              return { lat: lat, lon: lon };
            })
          : null;

      setShapeCordLatLan(shapeLatLon);

      return shape;
    }

    function showTooltip(e, lat, lon, distance) {
      if (drawingMode === "polyline") {
        var x = e?.endPosition?.x;
        var y = e?.endPosition?.y;

        document.getElementById("tooltip").innerHTML =
          "<strong>Latitude:</strong> " +
          lat +
          "<br><strong>Longitude:</strong> " +
          lon +
          "<br><strong>Distance:</strong> " +
          distance +
          " meters";

        document.getElementById("tooltip").style.left = x + 10 + "px";
        document.getElementById("tooltip").style.top = y - 10 + "px";
      }
    }

    function calcDist(lat1, lon1, lat2, lon2) {
      var R = 6371;
      var dLat = toRad(lat2 - lat1);
      var dLon = toRad(lon2 - lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    }

    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }

    function calculateAndDisplayLabelsForLineSegments(e) {
      for (let i = 0; i < lineSegmentLabels.length; i++) {
        viewer.entities.remove(lineSegmentLabels[i]);
      }
      lineSegmentLabels = [];

      // Calculate and display labels for line segments
      for (let i = 1; i < activeShapePoints.length; i++) {
        const cartographic1 = Cartographic.fromCartesian(
          activeShapePoints[i - 1]
        );
        const cartographic2 = Cartographic.fromCartesian(activeShapePoints[i]);

        const lat1 = MathOfCesium.toDegrees(cartographic1.latitude);
        const lon1 = MathOfCesium.toDegrees(cartographic1.longitude);
        const lat2 = MathOfCesium.toDegrees(cartographic2.latitude);
        const lon2 = MathOfCesium.toDegrees(cartographic2.longitude);

        const segmentDistance = (
          calcDist(lat1, lon1, lat2, lon2) * 1000
        ).toPrecision(6);

        showTooltip(
          e,
          lat2.toPrecision(8),
          lon2.toPrecision(8),
          segmentDistance
        );

        const midpointLat = (lat1 + lat2) / 2;
        const midpointLon = (lon1 + lon2) / 2;

        const labelPosition = Cartesian3.fromDegrees(
          midpointLon,
          midpointLat,
          0
        );

        let angle = Math.atan2(lon2 - lon1, lat2 - lat1);

        // Adjust the angle for vertical lines
        if (lat1 === lat2) {
          angle = 0; // Make the label horizontal for vertical lines
        } else {
          angle += Math.PI / 2; // Add 90 degrees to make the label parallel
        }

        const rotationDegrees = MathOfCesium.toDegrees(angle);

        const label = viewer.entities.add({
          position: labelPosition,
          label: {
            text: segmentDistance + " meters",
            font: "16px Helvetica",
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            outlineWidth: 2,
            style: LabelStyle.FILL_AND_OUTLINE,
            verticalOrigin: VerticalOrigin.CENTER,
            horizontalOrigin: HorizontalOrigin.CENTER,
            pixelOffset: new Cartesian2(0, -15),
            rotation: MathOfCesium.toRadians(rotationDegrees),
            translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
          },
        });

        lineSegmentLabels.push(label);
      }
    }

    const handler = new ScreenSpaceEventHandler(viewer.canvas);

    let handlerAdded = false;

    function terminateShape() {
      activeShapePoints.pop();
      drawShape(activeShapePoints);
      viewer.entities.remove(floatingPoint);
      viewer.entities.remove(activeShape);
      floatingPoint = undefined;
      activeShape = undefined;
      activeShapePoints = [];

      if (lineSegmentLabels.length > 0) {
        const lastLabel = lineSegmentLabels.pop();
        viewer.entities.remove(lastLabel);
      }

      dispatch(gisToolActions.turnOffTools());
      dispatch(gisToolActions.setselectedTool(null));
    }

    if (polylineAnnotation && !handlerAdded) {
      handler.setInputAction(function (event) {
        const ray = viewer.camera.getPickRay(event.position);
        const earthPosition = viewer.scene.globe.pick(ray, viewer.scene);

        if (defined(earthPosition)) {
          if (activeShapePoints.length === 0) {
            floatingPoint = createPoint(earthPosition);
            activeShapePoints.push(earthPosition);
            const dynamicPositions = new CallbackProperty(function () {
              if (drawingMode === "polygon") {
                return new PolygonHierarchy(activeShapePoints);
              }
              return activeShapePoints;
            }, false);
            activeShape = drawShape(dynamicPositions);
          }
          activeShapePoints.push(earthPosition);
          createPoint(earthPosition);

          document.getElementById("tooltip").style.display = "block";
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction(function (event) {
        if (defined(floatingPoint)) {
          const ray = viewer.camera.getPickRay(event.endPosition);
          const newPosition = viewer.scene.globe.pick(ray, viewer.scene);
          if (defined(newPosition)) {
            floatingPoint.position.setValue(newPosition);
            activeShapePoints.pop();
            activeShapePoints.push(newPosition);

            if (drawingMode === "polyline" && activeShapePoints.length > 1) {
              totalPolylineDistance = 0;
              if (lineSegmentLabels.length > 0) {
                const lastLabel = lineSegmentLabels.pop();
                viewer.entities.remove(lastLabel);
              }

              for (let i = 1; i < activeShapePoints.length; i++) {
                const cartographic1 = Cartographic.fromCartesian(
                  activeShapePoints[i - 1]
                );
                const cartographic2 = Cartographic.fromCartesian(
                  activeShapePoints[i]
                );

                const lat1 = MathOfCesium.toDegrees(cartographic1.latitude);
                const lon1 = MathOfCesium.toDegrees(cartographic1.longitude);
                const lat2 = MathOfCesium.toDegrees(cartographic2.latitude);
                const lon2 = MathOfCesium.toDegrees(cartographic2.longitude);

                const segmentDistance = (
                  calcDist(lat1, lon1, lat2, lon2) * 1000
                ).toPrecision(6);

                totalPolylineDistance += parseFloat(segmentDistance);

                // for removing extra line length
                if (i === activeShapePoints.length - 1) {
                  totalPolylineDistance -= parseFloat(segmentDistance);
                }
              }

              const lastIndex = activeShapePoints.length - 1;
              const lastPoint = activeShapePoints[lastIndex];
              const lat = MathOfCesium.toDegrees(
                Cartographic.fromCartesian(lastPoint).latitude
              ).toFixed(6);
              const lon = MathOfCesium.toDegrees(
                Cartographic.fromCartesian(lastPoint).longitude
              ).toFixed(6);

              showTooltip(
                event,
                lat,
                lon,
                totalPolylineDistance.toFixed(4),
                totalPolylineDistance
              );
              drawingMode === "polyline" &&
                calculateAndDisplayLabelsForLineSegments();
            }
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);

      handler.setInputAction(function (event) {
        let i;
        let lats = [];
        let lons = [];
        let vertices = [];

        for (i = 0; i < activeShapePoints.length; i++) {
          let startPoint = activeShapePoints[i];
          let endPoint = activeShapePoints[i + 1];

          let cartographic = Cartographic.fromCartesian(activeShapePoints[i]);

          let lat = MathOfCesium.toDegrees(cartographic.latitude);
          let lon = MathOfCesium.toDegrees(cartographic.longitude);

          lats.push(lat);
          lons.push(lon);

          let vertex = [activeShapePoints[i]["x"], activeShapePoints[i]["y"]];
          vertices.push(vertex);
        }

        if (lastPointLabelEntity) {
          viewer.entities.remove(lastPointLabelEntity);
        }

        drawingMode === "polyline" &&
          calculateAndDisplayLabelsForLineSegments();
        const lastIndex = activeShapePoints.length - 1;
        const lastPoint = activeShapePoints[lastIndex - 1];
        const lat = MathOfCesium.toDegrees(
          Cartographic.fromCartesian(lastPoint).latitude
        ).toPrecision(6);
        const lon = MathOfCesium.toDegrees(
          Cartographic.fromCartesian(lastPoint).longitude
        ).toPrecision(6);

        if (drawingMode === "polyline" && activeShapePoints.length > 2) {
          viewer.entities.add({
            position: lastPoint,
            label: {
              text: totalPolylineDistance.toFixed(4) + " meters",
              font: "16px Helvetica",
              fillColor: Color.GREEN,
              outlineColor: Color.BLACK,

              verticalOrigin: VerticalOrigin.CENTER,
              horizontalOrigin: HorizontalOrigin.RIGHT,
              showBackground: true,
              backgroundColor: Color.WHITE,
              translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
            },
          });
        }

        setShapeData({
          totalLengthofSegment: totalPolylineDistance,
        });

        document.getElementById("tooltip").style.display = "none";
        if (activeShapePoints.length <= 2) {
          viewer.entities.remove(point);
          terminateShape();
        } else {
          terminateShape();
          drawingMode === "polyline" &&
            polylineAnnotation &&
            setOpenPolylineAnnotationModal();
        }
      }, ScreenSpaceEventType.RIGHT_CLICK);

      handlerAdded = true;
    } else if (polylineAnnotation === false && handlerAdded) {
      handler.destroy();
      handlerAdded = false;
    }

    viewer.camera.lookAtTransform(Matrix4.IDENTITY);

    return () => {
      if (handler && !handler.isDestroyed()) {
        handler.destroy();
      }
    };
  }, [polylineAnnotation]);
}
