import React, { useState } from "react";
import {
  Modal,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { S3Apis } from "../../Services/ThirdPartyApi/AWS/S3";
import { AnnotationManager } from "../../Services/ThirdPartyApi/AWS/DynamoDB";

import ImageSlider from "./ImageSlider";
import PropTypes from "prop-types";
import CommentBox from "./CommentBox";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import { formatDate } from "../../Features/GIS/Utils/otherUtils";
import {
  pilObservationCategories,
  pilObservations,
  pilTaskStatus,
} from "../../Data/config/pilData";
import { copyIcon } from "../../Assets/icons/gisIcons/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const dropzoneStyle = {
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  border: "2px dashed #cccccc",
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full w-full">{children}</div>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MetaDataModal = ({
  isOpen,
  onClose,
  name,
  id,
  markerData,
  onDeleteMarker,

  images,
}) => {
  const [isEditMode, setIsEditMode] = React.useState(true);

  const [description, setDescription] = React.useState(markerData?.description);
  const [type, setType] = React.useState(markerData?.typeName);
  const [category, setCategory] = React.useState(markerData?.category);
  const [severity, setSeverity] = React.useState(markerData?.severity);
  const [uploadedFile, setUploadedFile] = React.useState(null);

  const [taskStatus, setTaskStatus] = useState(markerData?.taskStatus);
  const [addedBy, setAddedBy] = useState(markerData?.addedBy);
  const [verifiedBy, setVerifiedBy] = useState(markerData?.verifiedBy);
  const [reviewedBy, setReviewedBy] = useState(markerData?.reviewedBy);

  const [value, setValue] = React.useState(0);

  // redux
  const dispatch = useDispatch();

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setType(markerData?.type);
    setCategory(markerData?.category);
    setSeverity(markerData?.severity);
    setDescription(markerData?.description);
    setUploadedFile(null);
    setTaskStatus(markerData?.taskStatus);
    setAddedBy(markerData?.addedBy);
    setVerifiedBy(markerData?.verifiedBy);
    setReviewedBy(markerData?.reviewedBy);
    setIsEditMode(!isEditMode);
  };

  const resetData = () => {
    setType(markerData?.type);
    setCategory(markerData?.category);
    setSeverity(markerData?.severity);
    setDescription(markerData?.description);
    setUploadedFile(null);
    setTaskStatus(markerData?.taskStatus);
    setAddedBy(markerData?.addedBy);
    setVerifiedBy(markerData?.verifiedBy);
    setReviewedBy(markerData?.reviewedBy);
    setIsEditMode(!isEditMode);
  };

  const updateAnnotation = async (id, timestamp) => {
    var imagedata = [];
    if (uploadedFile) {
      const imageData = await S3Apis.uploadData(uploadedFile, "images");
      imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
    }

    const key = {
      id: id,
      timestamp: timestamp,
    };
    const fieldsToUpdate = {
      typeName: type,
      category,
      severity,
      description,
      taskStatus,
      addedBy,
      verifiedBy,
      reviewedBy,
      updatedOn: formatDate(Date.now()),
      images: imagedata,
    };

    try {
      AnnotationManager.editAnnotations(key, fieldsToUpdate);
      toast.success("Changes Saved Successfully!");
      dispatch(gisActions.refCounterUpdate());
      setIsEditMode(true);
      onClose();
    } catch (error) {
      toast.error("Something went wrong!!");
    }
  };

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };
  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const copyToClipboard = () => {
    const idToCopy = markerData.id.split("-")[0];
    navigator.clipboard
      .writeText(idToCopy)
      .then(() => {
        toast.success(`Id ${idToCopy} copied successfully  `);
        // You can also provide user feedback here if needed
      })
      .catch((err) => {
        console.error("Error copying ID to clipboard:", err);
        // Handle the error or provide user feedback
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setIsEditMode(true);
      }}
    >
      <div
        style={style}
        className="flex flex-col items-center justify-between bg-white w-full h-max rounded-[12px] "
      >
        {/* Modal Header */}
        <Toaster />
        <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          <IconButton
            aria-label="delete"
            onClick={handleEdit}
            size="small"
            className="text-[#211f1c]"
          >
            <EditIcon />
          </IconButton>
          <h3 className="text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
            {name}
          </h3>
          <div className="mr-[10px]">
            <IconButton
              onClick={() => {
                onClose();
                setIsEditMode(true);
              }}
              aria-label="Close"
              size="small"
              className="text-[#211f1c]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="grow h-full w-full p-3 custom-scrollbar">
          <Tabs value={value} onChange={handleChange} className="w-full">
            <Tab label="Description" {...a11yProps(0)} className="w-[50%]" />
            <Tab label="Comments" {...a11yProps(1)} className="w-[50%]" />
          </Tabs>
          <CustomTabPanel
            value={value}
            index={0}
            className="max-h-[80vh] overflow-y-auto"
          >
            <>
              <div className="grow w-full p-3 flex flex-col gap-3">
                {/* id  */}
                <div className="flex items-center justify-center w-full ">
                  <label className="w-[30%]">ID:</label>
                  <div className="flex flex-grow flex-col">
                    <p className="grow">{markerData?.id?.split("-")[0]}</p>
                  </div>
                  <button
                    onClick={copyToClipboard}
                    className="   text-white rounded"
                  >
                    <img
                      className="h-4"
                      src={copyIcon}
                      alt="copy id icon"
                      srcset=""
                    />{" "}
                  </button>
                </div>
                {/* type */}
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Type :</label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={type}
                        className="grow"
                        onChange={(e) => setType(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        {pilObservations.map((marker, index) => (
                          <MenuItem key={index} value={marker}>
                            {marker}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Type :</p>{" "}
                      <p>{markerData?.typeName ? markerData?.typeName : "-"}</p>
                    </label>
                  )}
                </div>
                {/* category  */}
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false &&
                  markerData?.filter_type !== "Polygon" ? (
                    <>
                      <label className="w-[30%]">Category:</label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={category}
                        className="grow"
                        onChange={(e) => setCategory(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        {pilObservationCategories.map((marker, index) => (
                          <MenuItem key={index} value={marker}>
                            {marker}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Category :</p>{" "}
                      <p>{markerData?.category ? markerData?.category : "-"}</p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Risk :</label>
                      <Select
                        className="grow"
                        size="small"
                        name="severity"
                        id="demo-simple-select-error"
                        value={severity}
                        onChange={(e) => setSeverity(e.target.value)}
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Require Immediate Review">
                          Require Immediate Review
                        </MenuItem>
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Risk :</p>{" "}
                      <p>{markerData?.severity ? markerData?.severity : "-"}</p>
                    </label>
                  )}
                </div>

                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Remark :</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Remark :</p>{" "}
                      <p>
                        {markerData?.description
                          ? markerData?.description
                          : "-"}
                      </p>
                    </label>
                  )}
                </div>

                <div className="flex items-center justify-center w-full">
                  <label className="w-[30%]">Image :</label>
                  <div className="flex flex-col  w-[70%] gap-2">
                    {images?.length > 0 ? (
                      <div className="h-[10%]">
                        <ImageSlider markerData={images} />
                      </div>
                    ) : (
                      <>{isEditMode && <p>No Images Available</p>}</>
                    )}

                    {!isEditMode && (
                      <div className="flex items-center justify-center w-full gap-2">
                        {!uploadedFile ? (
                          <>
                            <div
                              className="flex flex-col h-[30px] grow"
                              {...getRootProps()}
                              style={dropzoneStyle}
                            >
                              {/* <input {...getInputProps()} /> */}
                              <p className="m-auto text-xs">
                                Drag & Drop Image here or Click to upload
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <TextField
                              name="textNote"
                              size="small"
                              value={uploadedFile.name}
                              className="grow"
                              inputProps={{ readOnly: true }}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <p>Task Details</p>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Status :</label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={taskStatus}
                        className="grow"
                        onChange={(e) => setTaskStatus(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        {pilTaskStatus.map((status, index) => (
                          <MenuItem key={index} value={status}>
                            {status}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Status :</p>{" "}
                      <p>
                        {markerData?.taskStatus ? markerData?.taskStatus : "-"}
                      </p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Added by :</label>
                      <p className="grow">
                        {markerData?.addedBy ? markerData?.addedBy : "-"}
                      </p>
                      {/* <TextField
                        name="textNote"
                        size="small"
                        value={addedBy}
                        // onChange={(e) => setAddedBy(e.target.value)}
                        // InputProps={{
                        //   readOnly: isEditMode,
                        // }}
                        className="grow"
                      /> */}
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Added by :</p>{" "}
                      <p>{markerData?.addedBy ? markerData?.addedBy : "-"}</p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Verified by :</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={verifiedBy}
                        onChange={(e) => setVerifiedBy(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Verified by :</p>{" "}
                      <p>
                        {markerData?.verifiedBy ? markerData?.verifiedBy : "-"}
                      </p>
                    </label>
                  )}
                </div>

                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Reviewed by :</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={reviewedBy}
                        onChange={(e) => setReviewedBy(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Reviewed by :</p>{" "}
                      <p>
                        {markerData?.reviewedBy ? markerData?.reviewedBy : "-"}
                      </p>
                    </label>
                  )}
                </div>
              </div>
              {/* buttons  */}
              <div className="my-2 flex gap-2 items-center justify-center">
                {!isEditMode ? (
                  <>
                    <button
                      className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer my-2"
                      onClick={() => {
                        updateAnnotation(id, markerData?.timestamp);
                      }}
                    >
                      Save Changes
                    </button>
                    <button
                      className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer my-2"
                      onClick={resetData}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer my-2"
                    id="deleteButton"
                    onClick={() => {
                      onDeleteMarker(); // Call the delete callback
                      // refCounterUpdate();
                      dispatch(gisActions.refCounterUpdate());

                      onClose();
                    }}
                  >
                    Delete Marker
                  </button>
                )}
              </div>
            </>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CommentBox id={id} />
          </CustomTabPanel>
        </div>
      </div>
    </Modal>
  );
};

export default MetaDataModal;
