const GISUtils = {
  toNormalCase: (inputString) => {
    return inputString?.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
  },

  getTimelineTitle: (timeline) => {
    const date = timeline.split("_").slice(1);
    let resultDate = [date[0], GISUtils.toNormalCase(date[2]), date[1]].join(
      "/"
    );
    return resultDate;
  },
};
export default GISUtils;
