import React, { useId } from "react";
import axios from "axios";
import { Checkbox } from "@mui/material";
import "../../Assets/styles/GISStyle.css";
import { useSelector, useDispatch } from "react-redux";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const segregateAgpAssets = (objectsArray, keyword) => {
  const result = {
    Assets: [],
  };

  objectsArray.forEach((obj) => {
    if (
      (obj.type === "GEOJSON" || obj.type === "KML") &&
      obj.name.includes(keyword)
    ) {
      result.Assets.push(obj);
    } else {
      return null;
    }
  });

  return result;
};

const ShapeFilesIndexing = (props) => {
  const [assetData, setAssetData] = React.useState({});

  const assetId = useSelector((state) => state.gisHome.asset);
  const shapesIds = useSelector((state) => state.gisHome.shapesIds);
  const dispatch = useDispatch();
  const { grp } = useParams();

  // console.log(shapesIds);

  React.useEffect(() => {
    const getAssets = async () => {
      try {
        const response = await axios.get(`https://api.cesium.com/v1/assets`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
        });
        setAssetData(segregateAgpAssets(response.data.items, props.grp));
        // console.log(response.data.items, "response");
      } catch (error) {
        console.error(error);
      }
    };
    getAssets();
  }, [props.token]);

  function getOrdinal(number) {
    const suffixes = ["st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix = lastDigit <= 3 ? suffixes[lastDigit - 1] : "th";

    const colors = [
      "rgba(255, 255, 0, 0.8)", // Enhanced Yellow: Bright and vibrant yellow with 80% opacity
      "rgba(51, 204, 51, 0.8)", // Enhanced Green: Bright and vivid green with 80% opacity
      "rgba(0, 128, 255, 0.8)", // Enhanced Blue: Bright and vivid blue with 80% opacity
      "rgba(128, 0, 128, 0.8)", // New Color: Purple with 80% opacity
      "rgba(255, 153, 0, 0.8)", // Enhanced Orange: Distinct orange with 80% opacity
      "rgba(0, 204, 204, 0.8)", // Enhanced Cyan: Bright cyan with 80% opacity
      "rgba(153, 0, 255, 0.8)", // Enhanced Purple: Rich purple with 80% opacity
    ];

    const colorStyle = {
      borderColor: grp === "PIL" ? "#8c8c8c" : colors[number - 1] || "black",
      fontWeight: 500,
    };

    return (
      <>
        <div
          style={colorStyle}
          className="flex items-center px-[4px] py-[4px] text-xs rounded  border-2"
        >
          <p className="text-center">
            <span className="">{number}</span>
            <sup className="">{suffix}</sup>
          </p>
        </div>
      </>
    );
  }

  // console.log(assetId, "assetID  from comp");

  // Function to handle adding or removing items from selectedShapesIds
  const handleShapeSelection = (assetId) => {
    if (shapesIds.includes(assetId)) {
      dispatch(gisHomeActions.removeShapeId(assetId));
    } else {
      dispatch(gisHomeActions.addShapeId(assetId));
    }
  };
  function toNormalCase(inputString) {
    return inputString.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
  }

  const renderId = useId();

  return (
    <div
      className={`${
        props.openSidebar ? "block" : "hidden"
      } relative w-[275px] resize-able !grow border-solid border-[#d7d8d8] border-[1px] bg-[#fff] mr-2 ml-2 mb-1 mt-2  rounded-md`}
    >
      <div className="w-full rounded-tl-[1px] rounded-tr-[1px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
        <div className="grow flex items-center justify-center">Assets</div>
      </div>
      {Object.keys(assetData).map((category) => (
        <div key={category + renderId}>
          {assetData[category].length > 0 ? (
            assetData[category].map((asset, index) => {
              const assetSequence = shapesIds?.indexOf(asset.id) + 1;
              return (
                <>
                  <div className="flex flex-col" key={index}>
                    {!["KARMALA", "KARJAT"].includes(
                      asset.name.split("_").slice(1).join(" ")
                    ) ? (
                      <div
                        className={`box-showl flex justify-between items-center ${
                          assetId === asset.id
                            ? "bg-[#f0f8ff] rounded-[12px]"
                            : ""
                        }`}
                      >
                        {/* <Checkbox
                        color="active"
                        size="small"
                        id={asset.id}
                        checked={shapesIds?.includes(asset.id)}
                        onChange={() => handleShapeSelection(asset.id)}
                      /> */}

                        <input
                          type="checkbox"
                          id={asset.id}
                          name={`checkbox-${index}`}
                          checked={shapesIds?.includes(asset.id)}
                          className="ml-2 focus:ring-transparent rounded-md "
                          onChange={() => handleShapeSelection(asset.id)}
                        />

                        <div className="flex justify-between items-center w-full m-1 cursor-pointer py-1 px-2">
                          {asset.name.split("_").slice(1).join(" ")}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center">No Shapes to show</p>
          )}
        </div>
      ))}

      <div className="w-full rounded-tl-[1px] rounded-tr-[1px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
        <div className="grow flex items-center justify-center">
          Revenue Maps
        </div>
      </div>
      {Object.keys(assetData).map((category) => (
        <div key={category}>
          {assetData[category].length > 0 ? (
            assetData[category].map((asset, index) => {
              const assetSequence = shapesIds?.indexOf(asset.id) + 1;
              return (
                <>
                  <div className="flex flex-col" key={index}>
                    {["KARMALA", "KARJAT"].includes(
                      asset.name.split("_").slice(1).join(" ")
                    ) ? (
                      <div
                        className={`box-showl flex justify-between items-center ${
                          assetId === asset.id
                            ? "bg-[#f0f8ff] rounded-[12px]"
                            : ""
                        }`}
                      >
                        {/* <Checkbox
                        color="active"
                        size="small"
                        id={asset.id}
                        checked={shapesIds?.includes(asset.id)}
                        onChange={() => handleShapeSelection(asset.id)}
                      /> */}

                        <input
                          type="checkbox"
                          id={asset.id}
                          name={`checkbox-${index}`}
                          checked={shapesIds?.includes(asset.id)}
                          className="ml-2 focus:ring-transparent rounded-md "
                          onChange={() => handleShapeSelection(asset.id)}
                        />

                        <div className="flex justify-between items-center w-full m-1 cursor-pointer py-1 px-2">
                          {toNormalCase(
                            asset.name.split("_").slice(1).join(" ")
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center">No Revenue Maps to show</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ShapeFilesIndexing;
