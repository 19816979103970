import { useEffect } from "react";
import {
  Color,
  IonResource,
  GeoJsonDataSource,
  ScreenSpaceEventType,
  defined,
  KmlDataSource,
  PolylineDashMaterialProperty,
  Cartesian2,
  Cartesian3,
  NearFarScalar,
} from "cesium";
import { useSelector, useDispatch } from "react-redux";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { useParams } from "react-router-dom";
import { CSIco } from "../../../Assets/icons/gisIcons/icons";

/**
 * Custom React hook for displaying GeoJSON data on a CesiumJS map and handling interactions.
 *
 * @param {number[]} shapesIds - An array of selected shape IDs.
 * @param {object} viewerRef - A React ref object pointing to the Cesium viewer component.
 * @param {boolean} setZoomLevel - Function to set the zoom level.
 * @returns {void}
 * @version 1.0.0
 */

export function useGeoJsonOnMap(viewerRef) {
  const { shapesIds, asset } = useSelector((state) => state.gisHome);
  const { allObservations } = useSelector((state) => state.gis);
  // const { selectedTool } = useSelector((state) => state.gisTools);

  const dispatch = useDispatch();
  const { grp } = useParams();

  const { showComparer, annotationTool, measureToolToggle, polygonAnnotation } =
    useSelector((state) => state.gisTools);

  useEffect(() => {
    const highlighted = {
      feature: undefined,
      originalColor: new Color(),
    };

    const viewer = viewerRef.current;
    const removeAllDataSources = () => {
      if (viewer) {
        viewer.dataSources.removeAll();
      }
    };

    function getStationName(stationId) {
      switch (stationId) {
        case "CS01":
          return "Kakinada";
        case "CS02":
          return "Eluru";
        case "CS03":
          return "Suryapet";
        case "CS04":
          return "Pudur";
        case "CS05":
          return "Humnabad";
        case "CS06":
          return "Barshi";
        case "CS07":
          return "Shirur";
        case "CS08":
          return "Kalyan";
        case "CS09":
          return "Valsad";
        case "CS10":
          return "Bharuch";
        default:
          return "Unknown Station";
      }
    }

    const showGeoJsonOnMap = async (shapesIds) => {
      // if (!shapesIds || shapesIds.length === 0) {
      //   removeAllDataSources();
      //   return;
      // }
      if (viewerRef.current && shapesIds.length > 0) {
        removeAllDataSources();

        const colors = [
          new Color(1.0, 1.0, 0.5, 0.3), // Enhanced Yellow: Bright and vibrant yellow with 30% opacity
          new Color(0.2, 1.0, 0.0, 0.3), // Enhanced Green: Bright and vivid green with 30% opacity
          new Color(0.0, 0.5, 1.0, 0.3), // Enhanced Blue: Bright and vivid blue with 30% opacity
          new Color(0.5, 0.0, 0.5, 0.3), // New Color: Purple with 30% opacity
          new Color(1.0, 0.6, 0.0, 0.3), // Enhanced Orange: Distinct orange with 30% opacity
          new Color(0.0, 0.8, 0.8, 0.3), // Enhanced Cyan: Bright cyan with 30% opacity
          new Color(0.6, 0.0, 1.0, 0.3), // Enhanced Purple: Rich purple with 30% opacity
        ];

        let flyToFlag = 0;

        for (let i = 0; i < shapesIds.length; i++) {
          const assetId = shapesIds[i];
          const colorIndex = i % colors.length;

          try {
            if (grp !== "PIL") {
              const geoJsonResource = await IonResource.fromAssetId(assetId);
              const geoJsonDataSource = new GeoJsonDataSource(
                `customGeoJson_${assetId}`
              );

              await geoJsonDataSource.load(geoJsonResource);

              geoJsonDataSource.entities.values.forEach((entity) => {
                if (entity.polygon) {
                  entity.polygon.material = colors[colorIndex];
                  entity.polygon.outlineColor = colors[colorIndex];
                }
                if (entity.polyline) {
                  entity.polyline.material = colors[colorIndex];
                }
              });

              viewer.dataSources.add(geoJsonDataSource);

              // on left click showing the info of selected area
              if (
                showComparer === false &&
                annotationTool === false &&
                measureToolToggle === false &&
                polygonAnnotation === false
              )
                viewer.screenSpaceEventHandler.setInputAction((click) => {
                  const pickedFeature = viewer.scene.pick(click.position);
                  if (defined(pickedFeature) && pickedFeature.id) {
                    const featureId = pickedFeature.id.geoJsonId;

                    // alert(`featureId : ${featureId} `);
                  }
                }, ScreenSpaceEventType.LEFT_CLICK);

              geoJsonDataSource.entities.values.forEach((entity) => {
                const featureId = entity?.properties?.Id?.getValue();
                entity.geoJsonId = featureId;
              });
            } else {
              IonResource.fromAssetId(assetId)
                .then((resource) =>
                  KmlDataSource.load(resource, { clampToGround: true })
                )
                .then((dataSource) => {
                  viewer.dataSources.add(dataSource);

                  // console.log(dataSource.entities, "dataSource.entities");

                  dataSource.entities.values.forEach((entity) => {
                    // Modify appearance properties as needed
                    if (entity.polygon) {
                      entity.polygon.material = Color.RED;
                    } else if (entity.polyline) {
                      if (assetId === 2430028) {
                        entity.polyline.width = 2;
                        entity.polyline.granularity = 0.001;
                        entity.polyline.zIndex = 0;

                        entity.polyline.material =
                          new PolylineDashMaterialProperty({
                            dashLength: 16,
                            gapColor: Color.TRANSPERNT,
                          });
                      } else if (assetId === 2430029) {
                        entity.polyline.material = Color.BLUE;
                        entity.polyline.width = 2;
                        entity.polyline.granularity = 0.001;
                        entity.polyline.zIndex = 0;

                        entity.polyline.material =
                          new PolylineDashMaterialProperty({
                            color: Color.BLUE,
                            dashLength: 16,
                            gapColor: Color.TRANSPERNT,
                          });
                      } else if (assetId === 2408435) {
                        entity.polyline.material = Color.RED;
                        entity.polyline.width = 2;
                        entity.polyline.granularity = 0.001;
                        entity.polyline.zIndex = 0;
                      } else {
                        entity.polygon.material = Color.RED;
                      }
                    } else if (assetId === 2409704) {
                      if (entity.billboard) {
                        entity.billboard.image = CSIco;
                        entity.billboard.height = 40;
                        entity.billboard.width = 40;

                        entity.billboard.eyeOffset = new Cartesian3(0, 0, 20);
                      }

                      if (entity.label) {
                        entity.label.text = `${entity.name}\n${getStationName(
                          entity.name
                        )}`;
                        entity.label.eyeOffset = new Cartesian3(0, 0, 30);
                        entity.label.showBackground = true;
                        entity.label.backgroundColor = Color.BLACK;
                        entity.label.backgroundPadding = new Cartesian2(10, 5);
                        entity.label.translucencyByDistance = new NearFarScalar(
                          5.5e2,
                          1,
                          30.0e2,
                          0.0
                        );
                        entity.label.fillColor = Color.WHITE;
                      }
                    }
                  });

                  const entities = dataSource.entities.values;

                  if (asset === "") {
                    viewer.flyTo(dataSource, {
                      duration: 4.0,
                    });
                  }

                  // flyToFlag++;

                  // Zoom to the extent of the loaded data source
                  // viewer.zoomTo(dataSource);
                })
                .catch((error) => {
                  console.error("Error loading KMZ from Cesium ion:", error);
                });
            }
          } catch (error) {
            console.error(
              `Error loading GeoJSON for Asset ID ${assetId}:`,
              error
            );
          }
        }

        if (shapesIds) {
          // setZoomLevel(true);
          dispatch(gisActions.setZoomLevel());
        }
      }
    };

    showGeoJsonOnMap(shapesIds);
  }, [shapesIds]);
}
