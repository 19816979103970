import React, { useState } from "react";
import { IconButton, MenuItem, Modal, Select, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { AnnotationManager } from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { useDispatch, useSelector } from "react-redux";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import {
  pilObservationCategories,
  pilObservetionsRisks,
  pilObservations,
  pilTaskStatus,
} from "../../Data/config/pilData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDropzone } from "react-dropzone";
import { formatDate } from "../../Features/GIS/Utils/otherUtils";
import { S3Apis } from "../../Services/ThirdPartyApi/AWS/S3";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const PolylineAnnotationModal = ({
  isOpen,
  onClose,
  shapeData,
  shapeCord,
  grp,
  shapeCordLatLan,
}) => {
  // const [name, setName] = React.useState("");
  // const [description, setDescription] = React.useState("");
  // const [severity, setSeverity] = React.useState("");
  // const assetId = useSelector((state) => state.gisHome.asset);
  // const oriAnnotationManagerDB = new AnnotationManager("ori_annotations");
  // redux

  const dispatch = useDispatch();
  const assetId = useSelector((state) => state.gisHome.asset);
  const typeOfMarker = useSelector((state) => state.gis.markerCategory);

  const { first_name, last_name } = useSelector((state) => state.userData);

  const [selectedObservationType, setSelectedObservationType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSeverity, setSelectedSeverity] = useState(""); //risk
  const [description, setDescription] = useState(""); // remark
  const [uploadedFile, setUploadedFile] = useState(null); // image
  // task status
  const [observerName, setObserverName] = useState(
    `${first_name + " " + last_name}`
  );

  const [verifierName, setVerifierName] = useState();
  const [reviewerName, setReviewerName] = useState("");
  const [taskStatus, setTaskStatus] = useState(pilTaskStatus[0]);
  const { mlvName } = useSelector((state) => state.gis);

  const handleAnnotationSave = async () => {
    var imagedata;
    if (uploadedFile) {
      const imageData = await S3Apis.uploadData(uploadedFile, "images");
      imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
    }

    const dataToPush = {
      id: uuidv4(),
      assetId: assetId,
      timestamp: Date.now(),
      shapeCord,
      shapeData,
      typeName: selectedObservationType,
      category: selectedCategory,
      severity: selectedSeverity,
      description: description,
      markerName: "Observation",
      taskStatus: taskStatus,
      addedBy: observerName,
      verifiedBy: verifierName,
      reviewedBy: reviewerName,
      // markerDate: date,
      groupName: grp,
      createdOn: formatDate(Date.now()),
      images: imagedata,
      filter_type: "polyline",
      shapeCordLatLan: shapeCordLatLan,
      visible: true,
      polygon_images: [],
      mlvName: mlvName,
      timeline: assetId,
    };
    const response = AnnotationManager.addAnnotations(dataToPush);
    toast.success("Polyline Annotated Successfully ! ");
    // refCounterUpdate();
    // const dataForPdfGeneration = {
    //   annotation_id: dataToPush.id,
    //   table_name: "ori_annotations",
    //   annotation_timestamp: dataToPush.timestamp,
    //   polygon_cordinates: shapeCordLatLan, //lat_lon walla
    //   timeline: assetId,
    //   type: selectedObservationType, // for icon type
    //   shape_type: "polyline",
    // };

    // const res = await axios.post(
    //   "http://192.168.0.199:5000/create_ori_snapshot_image",
    //   dataForPdfGeneration

    // );

    // console.log(res, "res");
    dispatch(gisActions.refCounterUpdate());
  };

  const [expandedTask, setExpandedTask] = useState(false);

  const handleChangeTask = () => {
    expandedTask ? setExpandedTask(false) : setExpandedTask(true);
  };
  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const dropzoneStyle = {
    borderRadius: "4px",
    cursor: "pointer",
    textAlign: "center",
    border: "2px dashed #cccccc",
  };

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          dispatch(gisActions.refCounterUpdate());

          onClose();
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div
          style={style}
          className="w-1/3 flex flex-col items-center justify-between bg-white  !h-auto rounded-[12px]"
        >
          <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px]  w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
            <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
              Observation
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={() => {
                  dispatch(gisActions.refCounterUpdate());

                  onClose();
                }}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="grow w-full p-3 flex flex-col gap-3">
            <div className="grow w-full p-3 flex flex-col gap-3">
              {/* type selector  */}
              <div className="flex items-center justify-center w-full">
                <label className="w-[25%]">
                  Type: <span className="text-red-500">*</span>
                </label>
                <select
                  className="border border-gray-600 px-2 py-1 rounded grow"
                  value={selectedObservationType}
                  onChange={(event) => {
                    // setTypeOfMarker(event.target.value);
                    setSelectedObservationType(event.target.value);
                    // dispatch(gisActions.setMarkerCategory(event.target.value));
                  }}
                >
                  <option value="">Select Type</option>
                  {pilObservations?.map((marker, index) => (
                    <option key={index} value={marker}>
                      {marker}
                    </option>
                  ))}
                </select>
              </div>
              {/* observation selector  */}
              <div className="flex items-center justify-center w-full">
                <label className="w-[25%]">
                  Category: <span className="text-red-500">*</span>
                </label>
                <select
                  className="border border-gray-600 px-2 py-1 rounded grow"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    // setTypeOfMarker(event.target.value);
                    // dispatch(gisActions.setMarkerCategory(event.target.value));
                  }}
                >
                  <option value="">Select Category</option>
                  {pilObservationCategories?.map((marker, index) => (
                    <option key={index} value={marker}>
                      {marker}
                    </option>
                  ))}
                </select>
              </div>
              {/* risk selector  */}
              <div className="flex items-center justify-center w-full">
                <label className="w-[25%]">
                  Risk: <span className="text-red-500">*</span>
                </label>
                <select
                  className="border border-gray-600 px-2 py-1 rounded grow"
                  value={selectedSeverity}
                  onChange={(event) => setSelectedSeverity(event.target.value)}
                >
                  <option value="">Select a risk level</option>
                  {pilObservetionsRisks?.map((risk, index) => (
                    <option key={index} value={risk}>
                      {risk}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="flex items-center justify-center w-full ">
            <label className="w-[25%]">
              Name: <span className="text-red-500">*</span>{" "}
            </label>
            <div className="flex flex-grow flex-col">
              <TextField
                name="textNote"
                size="small"
                value={markerName}
                onChange={handleMarkerNameChange}
                className="grow"
                maxLength={25}
              />
              <p className="text-xs text-gray-400">
                Remaining characters: {remainingCharacters}
              </p>
            </div>
          </div> */}

              <div className="flex items-center justify-center w-full">
                <label className="w-[25%]">Remark: </label>
                <TextField
                  name="textNote"
                  size="small"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="grow"
                />
              </div>

              {/* <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">Date:</label>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{ flexGrow: 1 }}
            >
              <DemoContainer components={["DatePicker"]} sx={{ flexGrow: 1 }}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Timeline"
                    slotProps={{ textField: { size: "small" } }}
                    onChange={(selectedDate) => {
                      setDate(dayjs(selectedDate).format("DD_MM_YY"));
                    }}
                  />
                </FormControl>
              </DemoContainer>
            </LocalizationProvider>
          </div> */}
              {/* <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">Volume:</label>
            <TextField
              name="textNote"
              size="small"
              placeholder="in cubic meter"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              className="grow"
            />
          </div> */}

              {/* image upload  */}
              <div className="flex items-center justify-center w-full">
                <div className="w-[25%]">Add Image:</div>
                {!uploadedFile ? (
                  <>
                    <div
                      className="flex flex-col h-[30px] grow"
                      {...getRootProps()}
                      style={dropzoneStyle}
                    >
                      {/* <input {...getInputProps()} /> */}
                      <p className="m-auto text-xs">
                        Drag & Drop Image here or Click to upload
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <TextField
                      name="textNote"
                      size="small"
                      value={uploadedFile.name}
                      className="grow"
                      inputProps={{ readOnly: true }}
                    />
                  </>
                )}
              </div>

              <hr />
              <div>
                <div
                  className="flex items-center justify-between py-2 cursor-pointer"
                  onClick={handleChangeTask}
                >
                  Task Details
                  <span>
                    <ExpandMoreIcon className={expandedTask ? "rotated" : ""} />
                  </span>
                </div>
                <div
                  className={`flex flex-col gap-2 transition-all duration-300 ${
                    expandedTask
                      ? "opacity-100 max-h-screen"
                      : "opacity-0 max-h-0"
                  } overflow-hidden`}
                >
                  <div className="flex items-center justify-center w-full">
                    <label className="w-[25%]">Status</label>
                    <select
                      className="border border-gray-600 px-2 py-1 rounded grow"
                      value={taskStatus}
                      onChange={(event) => setTaskStatus(event.target.value)}
                    >
                      {/* <option value="">Select status</option> */}
                      {pilTaskStatus?.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center justify-center w-full ">
                    <label className="w-[25%]">Added By</label>
                    <div className="flex flex-grow flex-col">
                      <TextField
                        name="textNote"
                        size="small"
                        value={observerName}
                        onChange={(e) => setObserverName(e.target.value)}
                        className="grow"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-full ">
                    <label className="w-[25%]">Verified By</label>
                    <div className="flex flex-grow flex-col">
                      <TextField
                        name="textNote"
                        size="small"
                        value={verifierName}
                        onChange={(e) => setVerifierName(e.target.value)}
                        className="grow"
                        maxLength={25}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-full ">
                    <label className="w-[25%]">Reviewed By</label>
                    <div className="flex flex-grow flex-col">
                      <TextField
                        name="textNote"
                        size="small"
                        value={reviewerName}
                        onChange={(e) => setReviewerName(e.target.value)}
                        className="grow"
                        maxLength={25}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">Severity:</label>
              <Select
                className="grow"
                size="small"
                name="severity"
                id="demo-simple-select-error"
                value={severity}
                onChange={(e) => setSeverity(e.target.value)}
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Require Immediate Review">
                  Require Immediate Review
                </MenuItem>
              </Select>
            </div> */}
            {/* buttons  */}
          </div>
          <div className="flex gap-2 justify-center items-center mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                handleAnnotationSave();
                onClose();
              }}
            >
              Save
            </button>

            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                dispatch(gisActions.refCounterUpdate());

                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PolylineAnnotationModal;
