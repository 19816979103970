import * as AWS from "aws-sdk";

const configuration = {
  region: "ap-south-1",
  secretAccessKey: "aNxVVkZLBNu3aneI85TrHI6pPyIzAFb72bDKlvXP",
  accessKeyId: "AKIAX546GMWHGDP77WX6",
};

AWS.config.update(configuration);
const docClient = new AWS.DynamoDB.DocumentClient();
const tableName = "ori_annotations";

export class DynamoDBApis {
  // constructor(tableName) {
  //   this.tableName = tableName;
  // }

  //get all entries from a given table
  static getAllData = () => {
    docClient.scan(
      {
        TableName: tableName,
      },
      (err, data) => {
        if (err) {
          console.error("Error scanning table:", err);
          return false;
        } else {
          // console.log("Items in the table:", data.Items);
          return data.Items;
        }
      }
    );
  };

  //get entries based on a given assetID in a given table
  static getDataByID = (assetID) => {
    const filterExpression = "assetId = :assetId";
    const expressionAttributeValues = {
      ":assetId": assetID,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          // console.log("Items with assetID:", data.Items);
          resolve(data.Items);
        }
      });
    });
  };

  static getDataByProject = (projectName) => {
    const filterExpression = "project_name = :project_name";
    const expressionAttributeValues = {
      ":project_name": projectName,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          // console.log("Items with assetID:", data.Items);
          resolve(data.Items);
        }
      });
    });
  };

  static getDataByMLVName = (mlvName) => {
    const filterExpression = "mlvName = :mlvName";
    const expressionAttributeValues = {
      ":mlvName": mlvName,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          // console.log("Items with assetID:", data.Items);
          resolve(data.Items);
        }
      });
    });
  };

  static getDataBygrpID = (grpID) => {
    const filterExpression = "grpID = :grpID";
    const expressionAttributeValues = {
      ":grpID": grpID,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          // console.log("Items with assetID:", data.Items);
          resolve(data.Items);
        }
      });
    });
  };

  static getDataByFilter = (
    typeName,
    severity,
    assetID,
    searchQuery,
    groupName,
    mlvName
  ) => {
    let filterExpression = "";
    let expressionAttributeValues = {};

    if (assetID) {
      if (typeName && severity) {
        // If both typeName and severity are selected
        filterExpression =
          "groupName = :groupName AND typeName = :typeName AND severity = :severity AND assetId = :assetId AND mlvName = :mlvName";
        expressionAttributeValues[":typeName"] = typeName;
        expressionAttributeValues[":severity"] = severity;
        expressionAttributeValues[":assetId"] = assetID;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      } else if (typeName) {
        // If only typeName is selected
        filterExpression =
          "groupName = :groupName AND typeName = :typeName AND assetId = :assetId AND mlvName = :mlvName";
        expressionAttributeValues[":typeName"] = typeName;
        expressionAttributeValues[":assetId"] = assetID;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      } else if (severity) {
        // If only severity is selected
        filterExpression =
          "groupName = :groupName AND severity = :severity AND assetId = :assetId AND mlvName = :mlvName";
        expressionAttributeValues[":severity"] = severity;
        expressionAttributeValues[":assetId"] = assetID;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      } else {
        // If neither typeName nor severity is selected
        filterExpression =
          "groupName = :groupName AND assetId = :assetId AND mlvName = :mlvName";
        expressionAttributeValues[":assetId"] = assetID;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      }
    } else {
      if (typeName && severity) {
        // If both typeName and severity are selected
        filterExpression =
          "groupName = :groupName AND typeName = :typeName AND severity = :severity AND mlvName = :mlvName";
        expressionAttributeValues[":typeName"] = typeName;
        expressionAttributeValues[":severity"] = severity;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      } else if (typeName) {
        // If only typeName is selected
        filterExpression =
          "groupName = :groupName AND typeName = :typeName AND mlvName = :mlvName";
        expressionAttributeValues[":typeName"] = typeName;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      } else if (severity) {
        // If only severity is selected
        filterExpression =
          "groupName = :groupName AND severity = :severity AND mlvName = :mlvName";
        expressionAttributeValues[":severity"] = severity;
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      } else {
        // If neither typeName nor severity is selected
        filterExpression = "groupName = :groupName AND mlvName = :mlvName";
        expressionAttributeValues[":groupName"] = groupName;
        expressionAttributeValues[":mlvName"] = mlvName;
      }
    }

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          // console.log("Items with assetID:", data.Items);
          const filteredData = data.Items;
          if (!searchQuery) {
            resolve(filteredData);
          } else {
            const filteredResults = filteredData.filter((item) =>
              item.markerName.includes(searchQuery)
            );
            resolve(filteredResults);
          }
        }
      });
    });
  };

  static addReport = (dataToPush) => {
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          return "Annotated Sucessfully";
        }
      }
    );
  };

  static addStreamLinks = (dataToPush) => {
    // console.log(dataToPush);
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          // return "Annotated Sucessfully";
        }
      }
    );
  };
}

export class AnnotationManager {
  // constructor(tableName) {
  //   this.tableName = tableName;
  // }

  static addAnnotations = (dataToPush, file) => {
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          return "Annotated Sucessfully";
        }
      }
    );
  };

  static deleteAnnotations = (getId, getTimestamp) => {
    docClient.delete(
      {
        TableName: tableName,
        Key: { id: getId, timestamp: getTimestamp },
      },
      (err, data) => {
        if (err) {
          console.error("Error deleting item:", err);
          return false;
        } else {
          // console.log("Item deleted successfully:", data);
          return "Item deleted successfully";
        }
      }
    );
  };

  static editAnnotations = (key, fieldsToUpdate) => {
    // Construct the update expression and expression attribute values dynamically
    let updateExpression = "SET ";
    const expressionAttributeValues = {};

    // Loop through the fields to update object and build the update expression and values
    Object.keys(fieldsToUpdate).forEach((field, index, fieldsArray) => {
      if (field === "images") {
        // Handle the 'images' attribute separately to push the new object
        updateExpression +=
          " #images = list_append(if_not_exists(#images, :empty_list), :new_image)";
        expressionAttributeValues[":new_image"] = fieldsToUpdate[field];
        expressionAttributeValues[":empty_list"] = [];

        // Add a comma if there are more fields to update
        if (index < fieldsArray.length - 1) {
          updateExpression += ", ";
        }
      } else if (fieldsToUpdate[field]) {
        updateExpression += `${field} = :new_value_${index}`;
        expressionAttributeValues[`:new_value_${index}`] =
          fieldsToUpdate[field];

        // Add a comma if there are more fields to update
        if (index < fieldsArray.length - 1) {
          updateExpression += ", ";
        }
      }
    });

    // Define the parameters for the update operation
    const params = {
      TableName: tableName,
      Key: key,
      UpdateExpression: updateExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      ExpressionAttributeNames: {
        "#images": "images", // Specify the attribute name for 'images'
      },
      ReturnValues: "ALL_NEW",
    };

    // Perform the update operation
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Error updating item:", err);
      } else {
        // console.log("Item updated successfully:", data.Attributes); // Access the updated item
      }
    });
  };
}

export class CommentManager {
  // constructor(tableName) {
  //   this.tableName = tableName;
  // }

  static addComments = (dataToPush) => {
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          return "Annotated Sucessfully";
        }
      }
    );
  };

  static getCommentsByMarkerID = (markerID) => {
    const filterExpression = "markerID = :markerID";
    const expressionAttributeValues = {
      ":markerID": markerID,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          const sortedItems = data.Items.sort((a, b) => {
            return b.timestamp - a.timestamp;
          });
          resolve(sortedItems);
        }
      });
    });
  };

  static deleteComment = (id, timestamp) => {
    docClient.delete(
      {
        TableName: tableName,
        Key: { id, timestamp },
      },
      (err, data) => {
        if (err) {
          console.error("Error deleting item:", err);
          return false;
        } else {
          // console.log("Item deleted successfully:", data);
          return "Item deleted successfully";
        }
      }
    );
  };

  static updateCommentById = (key, newComment) => {
    const params = {
      TableName: tableName,
      Key: key,
      UpdateExpression: "SET #comment = :newComment", // Update the 'comment' attribute
      ExpressionAttributeValues: {
        ":newComment": newComment,
      },
      ExpressionAttributeNames: {
        "#comment": "comment", // Define the alias for 'comment'
      },
      ReturnValues: "ALL_NEW", // Return the updated item
    };

    return new Promise((resolve, reject) => {
      docClient.update(params, (err, data) => {
        if (err) {
          console.error("Error updating item:", err);
          reject(err);
        } else {
          resolve(data.Attributes); // Return the updated item's attributes
        }
      });
    });
  };
}

export const getPolyAnnotation = (assetId, filter_type) => {
  const filterExpression = "filter_type = :filter_type AND assetId = :assetId";
  const expressionAttributeValues = {
    ":assetId": assetId,
    ":filter_type": filter_type,
  };

  const params = {
    TableName: "ori_annotations",
    FilterExpression: filterExpression,
    ExpressionAttributeValues: expressionAttributeValues,
  };

  return new Promise((resolve, reject) => {
    docClient.scan(params, (err, data) => {
      if (err) {
        console.error("Error querying table:", err);
        reject(err);
      } else {
        // console.log("Poly:", data.Items);
        resolve(data.Items);
      }
    });
  });
};
