import { useMemo, useRef, useState } from "react";
import { DynamoDBApis } from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme// Choose a theme, here I'm using the 'alpine' theme

import "rc-pagination/assets/index.css";
import { getDate } from "../../Utils/OtherUtils";
import { downIco } from "../../Assets/icons/gisIcons/icons";
import { AnnotationReport } from "./AnnotationReport";
import { useParams } from "react-router";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
// import { handleFlyToMarkerUtil } from "../../Utils/CesiumUtils";
import CesiumUtils from "../../Utils/CesiumUtils";
import GISUtils from "../../Utils/GISUtils";
import { PILDates } from "../../Data/config/pilData";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AgGridReact } from "ag-grid-react";
import { Modal } from "@mui/material";
import axios from "axios";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <> {children}</>}
    </div>
  );
}

const BottomReportTable = ({
  isSidebaropen,
  openShapes,
  viewer,
  openObservation,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [inheritedData, setInheritedData] = useState([]);
  const [assetFilter, setAssetFilter] = useState();
  const [categoryFilter, setCategoryFilter] = useState();
  console.log(categoryFilter, "categoryFilter");
  const [severityFilter, setSeverityFilter] = useState();
  const { bottomReportToggle, asset } = useSelector((state) => state.gisHome);
  const {
    markerArray,
    polylineAnnotationsData,
    polygonAnnotationsData,
    allObservations,
    mlvName,
  } = useSelector((state) => state.gis);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const { grp } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = useMemo(
    () => async () => {
      try {
        const retrievedData = await DynamoDBApis.getDataByFilter(
          categoryFilter,
          severityFilter,
          assetFilter ? assetFilter : null,
          searchQuery !== "" ? searchQuery : null,
          grp,
          mlvName
        );

        setFilteredData(retrievedData);
        console.log(retrievedData, "retrievedData");
      } catch (error) {
        console.error(error);
      }
    },
    [
      assetFilter,
      categoryFilter,
      severityFilter,
      searchQuery,
      grp,
      markerArray,
      polylineAnnotationsData,
      polygonAnnotationsData,
      mlvName,
    ]
  );
  useEffect(() => {
    asset && fetchData();
  }, [fetchData]);

  const predefinedCategories = [
    "Categories",
    "Building",
    "Excavation Area",
    "HT Line",
    "Marker",
    "MLV Station",
    "Pylon",
    "Structure ",
    "Tree",
    "Other",
  ];
  const severities = ["Low", "Medium", "High"];

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // report modal
  const [openAnnoReport, setOpenAnnoReport] = useState(false);

  // const handleCloseModal = () => {
  //   setOpenAnnoReport(null);
  // };
  const myElementRef = useRef(null);

  const [elementWidth, setElementWidth] = useState(null);

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.markerName,
    },
    {
      name: "Added By",
      selector: (row) => row.addedBy,
    },
    {
      name: "Timeline",
      selector: (row) => GISUtils.getTimelineTitle(row.assetId),
    },
    {
      name: "Type",
      selector: (row) => row.typeName,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Created On",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.description || "-",
    },
    {
      name: "ID",
      selector: (row) => row.id.split("-")[0],
      onRowClicked: (row) => {
        alert(row);
      },
    },
    {
      name: "Reviewed By",
      selector: (row) => row.reviewedBy || "-",
    },
    {
      name: "Risk",
      selector: (row) => row.severity,
      conditionalCellStyles: [
        {
          when: (row) => row.severity === "High",
          style: {
            color: "red",
          },
        },
        {
          when: (row) => row.severity === "Medium",
          style: {
            color: "orange",
          },
        },
        {
          when: (row) => row.severity === "Low",
        },
      ],
    },
    {
      name: "Task Status",
      selector: (row) => row.taskStatus || "-",
    },
    {
      name: "Last Update",
      selector: (row) => row.updatedOn || "NA",
    },
  ];

  const ImageRenderer = ({ value }) => (
    <div className="flex gap-2">
      {value.map((img, index) => (
        <img
          className="h-8"
          key={index}
          src={`https://d2boh9caghjgas.cloudfront.net/${img.path}`}
          alt={img.name}
        />
      ))}
    </div>
  );
  const columnsOfDiff = [
    {
      headerName: "Name",
      field: "markerName",
    },
    {
      headerName: "Added By",
      field: "addedBy",
    },
    {
      headerName: "Timeline",
      field: "assetId",
      valueGetter: (params) => GISUtils.getTimelineTitle(params.data.assetId),
    },
    {
      headerName: "Type",
      field: "typeName",
    },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
    },
    {
      headerName: "Created On",
      field: "createdOn",
      sortable: true,
    },
    {
      headerName: "Remark",
      field: "description",
      valueGetter: (params) => params.data.description || "-",
    },
    {
      headerName: "ID",
      field: "id",
      valueGetter: (params) => params.data.id.split("-")[0],
    },
    {
      headerName: "Reviewed By",
      field: "reviewedBy",
      valueGetter: (params) => params.data.reviewedBy || "-",
    },
    {
      headerName: "Risk",
      field: "severity",

      cellStyle: (params) => {
        if (params.value === "High") {
          return {
            color: "red",
            fontWeight: 600,
            backgroundColor: "rgb(255 0 0 / 10%)",
          };
        } else if (params.value === "Medium") {
          return {
            color: "orange",
            fontWeight: 600,
            backgroundColor: "rgb(255 165 0 / 10%)",
          };
        } else {
          return {
            fontWeight: 600,
            backgroundColor: "rgb(255 255 0 / 23%)",
          };
        }
      },
    },
    {
      headerName: "Task Status",
      field: "taskStatus",
      valueGetter: (params) => params.data.taskStatus || "-",
    },
    {
      headerName: "View",
      cellRenderer: function (params) {
        return `Click to view`;
      },

      // field: "polygon_images",
      onCellClicked: (params) => {
        // alert(`Cell clicked: ${params.data.polygon_images}`);
        handleCellClicked(params);
      },

      cellRendererFramework: ImageRenderer,
    },
  ];
  // .map((marker) => {
  //   return {
  //     url: `https://d2boh9caghjgas.cloudfront.net/${marker.path}`,
  //     date: marker.date,
  //   };

  useEffect(() => {
    const otherItem = document.getElementById("GISSidebarBody");

    if (otherItem) {
      const width = otherItem.offsetWidth;
      const component = document.getElementById("BottomReporttable");

      if (component) {
        component.style.width = `calc(100vw - ${width}px)`;
      }
    }
  }, [isSidebaropen, openShapes, openObservation]);

  const keysToExclude = [
    "shapeCord",
    "shapeCordLatLan",
    "shapeData",
    "visible",
    "timestamp",
  ];

  const gridRef = useRef();

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: ["columns"],
    };
  }, []);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objectKeys, setObjectKeys] = useState([]);
  console.log(objectKeys, "objectKeys");

  const titleMapping = {
    addedBy: "Added By",
    filter_type: "Shape Type",
    severity: "Risk",
    timestamp: "Timestamp",
    groupName: "Group Name",
    reviewedBy: "Reviewed By",
    createdOn: "Created On",
    mlvName: "MLV Name",
    taskStatus: "Task Status",
    typeName: "Type",
    category: "Category",
    markerName: "Name",
    description: "Description",
    id: "Observation ID",
    polygon_images: "",
  };
  const keysToExcludeForDiff = [
    "shapeCord",
    "shapeCordLatLan",
    "shapeData",
    "visible",
    "timestamp",
    "timeline",
    "assetId",
    "markerName",
    "groupName",
  ];
  const handleCellClicked = (params) => {
    const clickedItem = params.data; // Assuming the entire row data is the item you want to show

    if (
      clickedItem.timeline === "PIL_22_23_JUNE" &&
      clickedItem.filter_type === "polygon"
    ) {
      setSelectedItem(clickedItem);
      console.log(clickedItem, "clickedItem");
      setObjectKeys(
        Object.keys(selectedItem)
          .filter((key) => !keysToExcludeForDiff.includes(key))
          .sort()
      );
      setIsModalOpen(true);
    } else {
      toast.error(
        "Currently, the Diff view is only available for polygon observations."
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const generateReport = async () => {
    console.log(filteredData, "mohit");

    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/api/v1/getPdf/generate_pil_report",
        { data: filteredData },
        {
          responseType: "blob", // Tell Axios to expect a binary response
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });

      toast.success("PDF Created!");

      //pdfcreation ends here anand

      // Create a download link and trigger a click to initiate download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `report.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      //pdfcreation failed anand
      toast.error("PDF creation failed!");
      console.error("err", error);
    }
  };

  return (
    <>
      <div
        className={`z-50 absolute  bottom-0  left-[100%]`}
        id="BottomReporttable"
      >
        {!bottomReportToggle && (
          <div
            onClick={() => {
              dispatch(gisHomeActions.bottomReportToggle());
            }}
            className={`w-max bg-white flex rounded-tl-md rounded-tr-md`}
          >
            <div className="bg-yellow-400 rounded-tl-md px-2">
              {" "}
              <img src={downIco} className=" rotate-180" alt="" srcSet="" />
            </div>
            <h3 className="px-2  ">Detailed Reports</h3>
          </div>
        )}

        {bottomReportToggle && (
          <>
            <div className="   h-auto bg-white relative z-20 flex flex-col border justify-between border-l-gray-300 ">
              <div className="actionBarOfBottomReportTable flex flex-col  h-full ">
                <div className=" flex justify-between items-center h-[8vh] px-2 ">
                  <div className="h-8 flex items-center">
                    <h1 className="font-bold border-b-4 border-b-yellow-400">
                      Detailed Reports
                    </h1>
                  </div>
                  {/* filter options  */}
                  <div className="flex gap-2 p-2 overflow-hidden">
                    <select
                      className="border border-gray-400 rounded-md text-sm min-h-[1.5rem]"
                      value={assetFilter}
                      onChange={(e) =>
                        e.target.value === "All"
                          ? setAssetFilter(null)
                          : setAssetFilter(e.target.value)
                      }
                    >
                      <option value={"All"}>All Timelines</option>
                      {PILDates.map((PILDates, index) => (
                        <option key={index} value={PILDates.name}>
                          {grp === "PIL"
                            ? GISUtils.getTimelineTitle(PILDates.name)
                            : getDate(PILDates.date)}
                        </option>
                      ))}
                    </select>

                    <select
                      className="border border-gray-400 rounded-md text-sm min-h-[1.5rem] "
                      value={categoryFilter}
                      onChange={(e) =>
                        e.target.value === "All"
                          ? setCategoryFilter(null)
                          : setCategoryFilter(e.target.value)
                      }
                    >
                      {predefinedCategories.map((category, index) =>
                        category === "Categories" ? (
                          <option key={index} value="All">
                            All Types
                          </option>
                        ) : (
                          <option key={index} value={category}>
                            {category}
                          </option>
                        )
                      )}
                    </select>

                    <select
                      className="border border-gray-400 rounded-md text-sm m-h-[1.5rem]"
                      value={severityFilter}
                      onChange={(e) => {
                        e.target.value === "All"
                          ? setSeverityFilter(null)
                          : setSeverityFilter(e.target.value);
                      }}
                    >
                      <option value="All">All Risks</option>
                      {severities.map((severity, index) => (
                        <option key={index} value={severity}>
                          {severity}
                        </option>
                      ))}
                    </select>
                    <div className="searchBarBottomReports rounded-md">
                      <input
                        type="text"
                        name="searchBar"
                        placeholder="Search "
                        value={searchQuery}
                        onChange={handleInputChange}
                        className=" rounded-md  focus:border focus:border-b-2 "
                      />

                      {/* <button onClick={handleSearch}>Search</button> */}
                    </div>
                  </div>
                </div>
                <Toaster />
                <div className="max-h-[44vh]  min-h-[24vh] border-b">
                  {filteredData.length > 0 && asset ? (
                    <>
                      <div className={"ag-theme-quartz h-[35vh]"}>
                        <AgGridReact
                          ref={gridRef}
                          rowData={filteredData}
                          columnDefs={columnsOfDiff}
                          defaultColDef={{ flex: 1 }}
                          // defaultColDef={defaultColDef}
                          autoGroupColumnDef={autoGroupColumnDef}
                          sideBar={sideBar}
                          rowGroupPanelShow={"always"}
                          onRowDoubleClicked={(row) => {
                            const idToCopy = row.data.id.split("-")[0];
                            navigator.clipboard
                              .writeText(idToCopy)
                              .then(() => {
                                toast.success(
                                  `Id ${idToCopy} copied successfully  `
                                );
                                // You can also provide user feedback here if needed
                              })
                              .catch((err) => {
                                console.error(
                                  "Error copying ID to clipboard:",
                                  err
                                );
                                // Handle the error or provide user feedback
                              });
                          }}
                          onRowClicked={(row) => {
                            if (row.data.filter_type === "polygon") {
                              const shapeCordLen = row.data?.shapeCord.length;

                              CesiumUtils.handleFlyToMarkerUtil(
                                viewer,
                                row.data.filter_type,
                                row.data.shapeCord[shapeCordLen - 1]
                              );
                            } else {
                              CesiumUtils.handleFlyToMarkerUtil(
                                viewer,
                                row.data.filter_type,
                                {
                                  longitude: row.data.longitude,
                                  latitude: row.data.latitude,
                                }
                              );
                            }
                          }}
                        />
                        <Modal
                          open={isModalOpen}
                          onClose={handleCloseModal}
                          aria-labelledby="Diff-report-modal"
                        >
                          <div
                            className="bg-white rounded-md"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "80%",
                              height: "max-content",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div
                              id="modal-modal-title"
                              className="text-xl font-semibold mb-4 bg-yellow-300 p-2 text-center rounded-tl-md rounded-tr-md flex justify-center"
                            >
                              <p className="grow">Diff Report of Observation</p>

                              <button
                                onClick={handleCloseModal}
                                className="hover:text-red-500  text-gray-400 font-bold px-2 rounded-full text-lg "
                              >
                                X
                              </button>
                            </div>

                            <div className="timelinesHeader flex px-5  w-full  ">
                              <div className="header-title-box-2 grow text-center  py-2 bg-gray-100  font-medium rounded-tl-md">
                                <p className=" font-semibold tracking-wider">
                                  Headers
                                </p>
                              </div>
                              <div className="header-title-box-1  w-2/5 py-2 bg-gray-100 font-medium  ">
                                <p className="text-center font-semibold tracking-wider">
                                  {isModalOpen &&
                                  GISUtils?.getTimelineTitle(
                                    selectedItem["timeline"]
                                  ) === "22/June/23"
                                    ? "22/April/23"
                                    : null}
                                </p>
                              </div>

                              <div className="header-title-box-3  w-2/5 text-center  py-2 bg-gray-100  font-medium rounded-tr-md">
                                <p className="text-center font-semibold tracking-wider">
                                  {/* look overthis  */}
                                  {isModalOpen &&
                                    GISUtils?.getTimelineTitle(
                                      selectedItem["timeline"]
                                    )}
                                </p>
                              </div>
                            </div>

                            <div
                              className="p-5 pt-0"
                              style={{ height: "70vh", overflowY: "auto" }}
                            >
                              {objectKeys?.map((key, keyIndex) => (
                                <div
                                  key={keyIndex}
                                  className="diffTableRow border flex w-full"
                                >
                                  <div className="colInfo grow bg-gray-100  text-center py-1">
                                    <p className="text-center">
                                      {titleMapping[key]}
                                    </p>
                                  </div>
                                  <div className="infoT1 w-2/5 h-full py-1 border border-y-0 border-r-2 border-gray-200">
                                    {key === "polygon_images" ? (
                                      <img
                                        src={`https://d2boh9caghjgas.cloudfront.net/${selectedItem[key][0]?.["path"]}`}
                                        alt="diff report t1 snapshot"
                                        srcset=""
                                        className="mx-auto h-60 p-4 rounded-lg"
                                      />
                                    ) : key === "id" ? (
                                      <p className="text-center">
                                        {" "}
                                        {selectedItem[key].split("-")[0]}
                                      </p>
                                    ) : (
                                      <p className="text-center">
                                        {typeof selectedItem[key] === "object"
                                          ? JSON.stringify(selectedItem[key])
                                          : key === "timeline"
                                          ? GISUtils.getTimelineTitle(
                                              selectedItem[key]
                                            )
                                          : selectedItem[key]}
                                      </p>
                                    )}
                                  </div>

                                  <div className="infoT2 w-2/5 h-full  py-1">
                                    {key === "polygon_images" &&
                                    selectedItem["polygon_images"].length ===
                                      2 ? (
                                      <img
                                        src={`https://d2boh9caghjgas.cloudfront.net/${selectedItem[key][1]?.["path"]}`}
                                        alt="diff report t1 snapshot"
                                        srcset=""
                                        className="mx-auto p-4 h-60 rounded-lg"
                                      />
                                    ) : key === "id" ? (
                                      <p className="text-center">
                                        {" "}
                                        {selectedItem[key].split("-")[0]}
                                      </p>
                                    ) : (
                                      <p className="text-center">
                                        {typeof selectedItem[key] === "object"
                                          ? JSON.stringify(selectedItem[key])
                                          : key === "timeline"
                                          ? GISUtils.getTimelineTitle(
                                              selectedItem[key]
                                            )
                                          : selectedItem[key]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </>
                  ) : filteredData.length === 0 && asset ? (
                    <p className="text-center mt-12">
                      No Annotations with Selected Filters
                    </p>
                  ) : (
                    <p className="text-center mt-12">
                      Please select asset from timeline.
                    </p>
                  )}
                </div>
                <div className="flex gap-2 justify-end px-4 py-2">
                  <button
                    className="px-2 py-1 text-sm rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                    onClick={generateReport}
                  >
                    Export Report
                  </button>
                  {/* <button
                    className="px-2 py-1 text-sm rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                    onClick={() => {
                      asset === "PIL_22_23_JUNE"
                        ? console.log(
                            filteredData,
                            "export report filtered data"
                          )
                        : toast.error("No data to generate diff report.");
                    }}
                  >
                    Export Diff Report
                  </button> */}
                </div>
              </div>
            </div>
          </>
        )}
        {bottomReportToggle && (
          <button
            onClick={() => {
              dispatch(gisHomeActions.bottomReportToggle());
            }}
            className={` absolute -top-6 bg-red-400 right-0 rounded-tr-md rounded-tl-md px-2`}
          >
            <img src={downIco} className="" alt="" srcSet="" />{" "}
          </button>
        )}
      </div>

      {openAnnoReport && (
        <AnnotationReport
          onClose={handleCloseModal}
          elementWidth={elementWidth}
          selectedAsset={assetFilter}
          selectedCategory={categoryFilter}
          selectedSeverity={severityFilter}
        />
      )}
    </>
  );
};

export default BottomReportTable;
