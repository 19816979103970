import { useEffect } from "react";
import {
  Color,
  CallbackProperty,
  HeightReference,
  defined,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  LabelStyle,
  VerticalOrigin,
  HorizontalOrigin,
  Cartographic,
  Matrix4,
  Math as MathOfCesium,
  Cartesian2,
  Cartesian3,
  NearFarScalar,
} from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";

export function useLineMeasureTool(viewerRef, setShapeCords, setShapeData) {
  const dispatch = useDispatch();
  const measureToolToggle = useSelector(
    (state) => state.gisTools.lineMeasureTool
  );
  const { lineMeasureTool } = useSelector((state) => state.gisTools);

  useEffect(() => {
    const viewer = viewerRef.current;
    let drawingMode = "line";

    let activeShapePoints = [];
    let activeShape;
    let floatingPoint;
    let lineDistance = 0;
    let totalPolylineDistance = 0;
    let lastPointLabelEntity;
    let lineSegmentLabels = [];
    let point;

    function createPoint(worldPosition) {
      point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Color.GOLD,
          pixelSize: 10,
          heightReference: HeightReference.RELATIVE_TO_GROUND,
        },
      });
      return point;
    }

    function drawShape(positionData) {
      let shape;

      shape = viewer.entities.add({
        polyline: {
          positions: positionData,
          clampToGround: true,
          width: 3,
        },
      });

      setShapeCords(positionData);
      return shape;
    }

    function showTooltip(e, lat, lon, distance) {
      if (drawingMode === "line") {
        var x = e?.endPosition?.x;
        var y = e?.endPosition?.y;

        document.getElementById("tooltip").innerHTML =
          "<strong>Latitude:</strong> " +
          lat +
          "<br><strong>Longitude:</strong> " +
          lon +
          "<br><strong>Distance:</strong> " +
          distance +
          " meters";

        document.getElementById("tooltip").style.left = x + 10 + "px";
        document.getElementById("tooltip").style.top = y - 10 + "px";
      }
    }

    function calcDist(lat1, lon1, lat2, lon2) {
      var R = 6371; // km
      var dLat = toRad(lat2 - lat1);
      var dLon = toRad(lon2 - lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    }

    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }

    const handler = new ScreenSpaceEventHandler(viewer.canvas);
    // console.log(handler, "handler");

    let handlerAdded = false;

    function terminateShape() {
      activeShapePoints.pop();
      drawShape(activeShapePoints);
      viewer.entities.remove(floatingPoint);
      viewer.entities.remove(activeShape);
      floatingPoint = undefined;
      activeShape = undefined;
      activeShapePoints = [];

      if (lineSegmentLabels.length > 0) {
        const lastLabel = lineSegmentLabels.pop();
        viewer.entities.remove(lastLabel);
      }

      dispatch(gisToolActions.turnOffTools());
      dispatch(gisToolActions.setselectedTool(null));
    }

    if (lineMeasureTool && !handlerAdded) {
      handler.setInputAction(function (event) {
        const ray = viewer.camera.getPickRay(event.position);
        const earthPosition = viewer.scene.globe.pick(ray, viewer.scene);

        if (defined(earthPosition)) {
          if (activeShapePoints.length === 0) {
            floatingPoint = createPoint(earthPosition);
            activeShapePoints.push(earthPosition);
            const dynamicPositions = new CallbackProperty(function () {
              return activeShapePoints;
            }, false);
            activeShape = drawShape(dynamicPositions);
          }
          activeShapePoints.push(earthPosition);
          createPoint(earthPosition);

          document.getElementById("tooltip").style.display = "block";

          if (drawingMode === "line" && activeShapePoints.length > 2) {
            terminateShape();

            document.getElementById("tooltip").style.display = "none";

            const positionLabel = earthPosition;
            positionLabel.z = earthPosition.z;
            positionLabel.x = earthPosition.x;
            positionLabel.y = earthPosition.y - 2;
            viewer.entities.add({
              position: positionLabel,
              label: {
                text: lineDistance + " meters",
                font: "16px Helvetica",
                fillColor: Color.GREEN,
                outlineColor: Color.BLACK,
                verticalOrigin: VerticalOrigin.CENTER,
                horizontalOrigin: HorizontalOrigin.RIGHT,
                showBackground: true,
                backgroundColor: Color.WHITE,
                translucencyByDistance: new NearFarScalar(
                  3.5e2,
                  1,
                  30.0e2,
                  0.0
                ),
              },
            });
          }
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction(function (event) {
        if (defined(floatingPoint)) {
          const ray = viewer.camera.getPickRay(event.endPosition);
          const newPosition = viewer.scene.globe.pick(ray, viewer.scene);
          if (defined(newPosition)) {
            floatingPoint.position.setValue(newPosition);
            activeShapePoints.pop();
            activeShapePoints.push(newPosition);

            if (drawingMode === "line" && activeShapePoints.length === 2) {
              let cartographic1 = Cartographic.fromCartesian(
                activeShapePoints[0]
              );
              let cartographic2 = Cartographic.fromCartesian(
                activeShapePoints[1]
              );

              let lat1 = MathOfCesium.toDegrees(cartographic1.latitude);
              let lon1 = MathOfCesium.toDegrees(cartographic1.longitude);
              let lat2 = MathOfCesium.toDegrees(cartographic2.latitude);
              let lon2 = MathOfCesium.toDegrees(cartographic2.longitude);

              lineDistance = (
                calcDist(lat1, lon1, lat2, lon2) * 1000
              ).toPrecision(6);

              showTooltip(
                event,
                lat2.toPrecision(6),
                lon2.toPrecision(6),
                lineDistance
              );
            }
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);

      handler.setInputAction(function (event) {
        let i;
        let lats = [];
        let lons = [];
        let vertices = [];

        if (lastPointLabelEntity) {
          viewer.entities.remove(lastPointLabelEntity);
        }

        // Calculate and display labels for line segments here

        const lastIndex = activeShapePoints.length - 1;
        const lastPoint = activeShapePoints[lastIndex - 1];
        const lat = MathOfCesium.toDegrees(
          Cartographic.fromCartesian(lastPoint).latitude
        ).toPrecision(6);
        const lon = MathOfCesium.toDegrees(
          Cartographic.fromCartesian(lastPoint).longitude
        ).toPrecision(6);

        setShapeData({
          totalLengthofSegment: totalPolylineDistance,
        });
        if (activeShapePoints.length <= 2) {
          viewer.entities.remove(point);
          terminateShape();
        } else {
          terminateShape();
        }

        // setShapeCreated(true);

        document.getElementById("tooltip").style.display = "none";
      }, ScreenSpaceEventType.RIGHT_CLICK);

      handlerAdded = true;
    } else if (lineMeasureTool === false && handlerAdded) {
      handler.destroy();
      handlerAdded = false;
    }
    viewer.camera.lookAtTransform(Matrix4.IDENTITY);

    return () => {
      if (handler && !handler.isDestroyed()) {
        handler.destroy();
      }
    };
  }, [measureToolToggle]);
}
