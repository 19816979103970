import { useEffect } from "react";
import {
  OpenStreetMapImageryProvider,
  IonImageryProvider,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  SplitDirection,
  ImageryLayer,
  WebMapServiceImageryProvider,
  Cartesian3,
} from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { useParams } from "react-router-dom";

/**
 * Custom React hook for facilitating the comparison of assets in a CesiumJS viewer.
 *
 * @param {boolean} showCompare - Indicates whether to enable the asset comparison mode.
 * @param {string[]} compareAssets - An array containing asset IDs or references to assets to be compared.
 * @param {object} viewerRef - A React ref object pointing to the Cesium viewer component.
 * @returns {void}
 * @version 1.0.0
 */

export function useCompareAssets(viewerRef) {
  const compareAssets = useSelector((state) => state.gisHome.compareAssets);
  const showCompare = useSelector((state) => state.gisTools.showComparer);
  const dispatch = useDispatch();
  const { grp } = useParams();

  useEffect(() => {
    const viewer = viewerRef.current;

    // Remove all data sources and imagery layers
    viewer.dataSources.removeAll();
    viewer.entities.removeAll();

    const compareLayers = viewer.imageryLayers;
    const imageryLayer1Asset = compareAssets?.[0];
    const imageryLayer2Asset = compareAssets?.[1];

    if (showCompare) {
      viewer.imageryLayers.removeAll();

      const openStreetMapLayer = new OpenStreetMapImageryProvider();

      compareLayers.addImageryProvider(openStreetMapLayer);
    }

    if (!imageryLayer1Asset) {
      return;
    }

    // Create imagery layer for the first asset
    var imageryLayer1 = null;
    if (grp === "PIL") {
      const imageryLayer = new WebMapServiceImageryProvider({
        url: "https://gis.indrones.com/geoserver/pil/wms",
        layers: imageryLayer1Asset,
        // Replace with your actual workspace and layer names
        parameters: {
          transparent: "true",
          format: "image/png",
        },
      });
      imageryLayer1 = ImageryLayer.fromProviderAsync(imageryLayer);
    } else {
      imageryLayer1 = ImageryLayer.fromProviderAsync(
        IonImageryProvider.fromAssetId(imageryLayer1Asset)
      );
    }

    // Ensure that imageryLayer1 is defined
    if (!imageryLayer1) {
      return;
    }

    // Set up the ScreenSpaceEventHandler for the slider
    const slider = document.getElementById("slider");
    const handler = new ScreenSpaceEventHandler(slider);

    if (showCompare && compareAssets.length > 0) {
      imageryLayer1.splitDirection = SplitDirection.LEFT;

      // console.log("imageryLayer1", imageryLayer1);

      compareLayers.add(imageryLayer1);

      var imageryLayer2 = null;
      if (imageryLayer2Asset) {
        if (grp !== "PIL") {
          imageryLayer2 = ImageryLayer.fromProviderAsync(
            IonImageryProvider.fromAssetId(imageryLayer2Asset)
          );
        } else {
          const imageryLayer = new WebMapServiceImageryProvider({
            url: "https://gis.indrones.com/geoserver/pil/wms",
            layers: imageryLayer2Asset,
            // Replace with your actual workspace and layer names
            parameters: {
              transparent: "true",
              format: "image/png",
            },
          });
          imageryLayer2 = ImageryLayer.fromProviderAsync(imageryLayer);
        }
        if (imageryLayer2) {
          imageryLayer2.splitDirection = SplitDirection.RIGHT;
          compareLayers.add(imageryLayer2);
        }
      }

      grp === "PIL"
        ? viewer.camera.flyTo({
            destination: Cartesian3.fromDegrees(74.9255, 18.5002, 1000),
            // duration: 5.0, // Set the flight duration in seconds
          })
        : viewer.flyTo(imageryLayer1);

      const scene = viewer.scene;
      scene.splitPosition =
        slider?.offsetLeft / slider?.parentElement?.offsetWidth;

      let moveActive = false;

      function move(movement) {
        if (!moveActive) {
          return;
        }
        const relativeOffset = movement.endPosition.x;
        const splitPosition =
          (slider?.offsetLeft + relativeOffset) /
          slider.parentElement?.offsetWidth;
        slider.style.left = `${100.0 * splitPosition}%`;
        scene.splitPosition = splitPosition;
      }

      handler.setInputAction(function () {
        moveActive = true;
      }, ScreenSpaceEventType.LEFT_DOWN);

      handler.setInputAction(function () {
        moveActive = true;
      }, ScreenSpaceEventType.PINCH_START);

      handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
      handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);

      handler.setInputAction(function () {
        moveActive = false;
      }, ScreenSpaceEventType.LEFT_UP);

      handler.setInputAction(function () {
        moveActive = false;
      }, ScreenSpaceEventType.PINCH_END);
    } else {
      if (imageryLayer1) {
        compareLayers.remove(imageryLayer1, true);
      }
      if (imageryLayer2Asset) {
        const imageryLayer2 = compareLayers.get(1); // Assuming it's at index 1
        if (imageryLayer2) {
          compareLayers.remove(imageryLayer2, true);
        }
      }
      handler.destroy();
      viewer.imageryLayers.removeAll();
      const openStreetMapLayer = new OpenStreetMapImageryProvider();

      compareLayers.addImageryProvider(openStreetMapLayer);
      dispatch(gisActions.refCounterUpdate());
    }

    return () => {
      if (handler && !handler.isDestroyed()) {
        handler.destroy();
      }
    };
  }, [showCompare, compareAssets]);
}
