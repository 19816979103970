import React, { useState, useEffect, useRef } from "react";
import {
  addIco,
  chevronIcoDropdown,
  folderIco,
  folderOpenIco,
} from "../../Assets/icons/gisIcons/icons";
import "../../Assets/styles/GISStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Tooltip } from "@mui/material";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { gisActions } from "../../redux/slices/GIS/gis-slice";

import pilConfig from "../../Data/config/pilIndexing.json";
import GISUtils from "../../Utils/GISUtils";
import { PILDates } from "../../Data/config/pilData";

const SectionsIndexing = (props) => {
  const myElementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(null);
  const [defDropDown, setDefDropDown] = useState(false);

  const dispatch = useDispatch();
  const showComparer = useSelector((state) => state.gisTools.showComparer);
  const compareAssets = useSelector((state) => state.gisHome.compareAssets);
  const universalAsset = useSelector((state) => state.gisHome.asset);
  const { refCounter, latestAssetCounter } = useSelector((state) => state.gis);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingId, setLoadingId] = React.useState(null);
  const [selectedAssetDate, setSelectedAssetDate] = useState("");

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  const handleSectionClick = (sectionId) => {
    setSelectedSection((prevSelected) =>
      prevSelected === sectionId ? null : sectionId
    );
    setSelectedSubsection(null);
  };

  const handleSubsectionClick = (subsectionId) => {
    setSelectedSubsection((prevSelected) =>
      prevSelected === subsectionId ? null : subsectionId
    );
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const statusOpen = Boolean(anchorEl);
  const statusId = statusOpen ? "simple-popper" : undefined;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(
      gisHomeActions.setAsset({
        asset: "",
        assetType: "",
      })
    );
    if (props.grp == "ESSEL") {
      setDefDropDown(true);
    }
  }, []);

  function getLastWord(inputString) {
    const words = inputString.split("_");

    const lastWord = words[words.length - 1];
    const secondlastWord = words[words.length - 2];

    return lastWord;
  }

  const setCurrentTimelineFun = (timeline) => {
    dispatch(gisHomeActions.setSelectedTimeline(timeline));
  };

  return (
    <div
      ref={myElementRef}
      className={`${
        props.openSidebar ? "block" : "hidden"
      } sideanimation   relative  !grow resize-able  border-solid border-[#d7d8d8] border-[1px] bg-[#fff] mr-2 ml-2 mb-1 mt-2 rounded-md `}
    >
      <div className="w-full  flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500] rounded-tr-md rounded-tl-md">
        <div className="grow flex items-center justify-center">Sections</div>
      </div>

      <ul className="box-showl flex flex-col grow">
        {pilConfig?.sections?.map((section) => (
          <li
            className="w-full border-b cursor-pointer py-2 px-2 flex flex-col justify-between "
            key={section.sectionId}
          >
            <Tooltip
              title={"Contains " + section.subsections.length + " MLV's."}
              placement="right"
            >
              <div
                className="flex justify-between items-center"
                onClick={() => handleSectionClick(section.sectionId)}
              >
                <div className="flex gap-2 items-center ">
                  <img
                    src={
                      selectedSection === section?.sectionId
                        ? folderOpenIco
                        : folderIco
                    }
                    alt=""
                    srcset=""
                  />
                  <p> {section.sectionId}</p>
                </div>
                {/* <p>{section.subsections.length}</p> */}
                <img
                  src={chevronIcoDropdown}
                  alt=""
                  className={
                    selectedSection === section.sectionId
                      ? "h-6 rotated mr-2"
                      : "h-6 mr-2"
                  }
                  srcSet=""
                />
              </div>
            </Tooltip>
            {selectedSection === section.sectionId && (
              <ul>
                {section.subsections.map((subsection) => (
                  <li className="" key={subsection.subsectionId}>
                    <Tooltip
                      placement="right"
                      title={
                        "Contains " +
                        subsection.timelines.length +
                        " timelines."
                      }
                    >
                      <div
                        className="flex justify-between items-center pl-4  border-l-2"
                        onClick={() => {
                          dispatch(
                            gisActions.setMlvName(subsection.subsectionId)
                          );
                          handleSubsectionClick(subsection.subsectionId);
                        }}
                      >
                        <div className="flex gap-2 items-center">
                          <img
                            src={
                              selectedSubsection === subsection?.subsectionId
                                ? folderOpenIco
                                : folderIco
                            }
                            alt=""
                            srcset=""
                          />
                          <p> {subsection.subsectionId}</p>
                        </div>

                        <img
                          src={chevronIcoDropdown}
                          alt=""
                          className={
                            selectedSubsection === subsection.subsectionId
                              ? "h-6 rotated mr-2"
                              : "h-6 mr-2"
                          }
                          srcSet=""
                        />
                      </div>
                    </Tooltip>
                    {selectedSubsection === subsection.subsectionId && (
                      <ul className="pl-6">
                        {subsection.timelines.length > 0 ? (
                          subsection.timelines.map((timeline) => (
                            <li
                              className="pl-4  border-l-2"
                              key={timeline.timelineId + 1}
                            >
                              {timeline.timelineId}
                              <ul>
                                {PILDates?.map((asset, index) =>
                                  timeline.timelineId.toLowerCase() ===
                                  getLastWord(asset.name).toLowerCase() +
                                    " 2023" ? (
                                    <>
                                      <Tooltip
                                        placement="right"
                                        title={"Click to add "}
                                      >
                                        <div
                                          className={` flex grow  hover:bg-[#ffca0094] py-1 rounded-md   items-center ${
                                            universalAsset === asset.name
                                              ? "bg-[#ffca0094]"
                                              : showComparer === true
                                              ? "bg-white"
                                              : ""
                                          }`}
                                          key={index}
                                        >
                                          {showComparer && (
                                            <Checkbox
                                              color="default"
                                              size="small"
                                              id={index}
                                              checked={compareAssets.includes(
                                                asset.name
                                              )}
                                              disabled={
                                                compareAssets.includes(
                                                  asset.name
                                                ) && compareAssets.length > 3
                                              }
                                              onChange={() =>
                                                props.compareAssetsArray(
                                                  asset.name
                                                )
                                              }
                                            />
                                          )}

                                          <div
                                            className={`w-full  cursor-pointer px-2 pr-[10px] flex justify-between itmes-center   `}
                                            onClick={() =>
                                              dispatch(
                                                gisHomeActions.setAsset({
                                                  asset: asset.name,
                                                  assetType: "GEOSERVER",
                                                })
                                              )
                                            }
                                          >
                                            <p
                                              className={`pl-4 ${
                                                universalAsset === asset.name
                                                  ? null
                                                  : "border-l-2"
                                              }`}
                                            >
                                              {getLastWord(
                                                asset.name
                                              ).toLocaleLowerCase() === "DSM"
                                                ? "DSM"
                                                : "ORI"}
                                            </p>

                                            <img
                                              src={addIco}
                                              className="h-5 border-2 my-auto border-[#808080] rounded-full"
                                              alt=""
                                              srcset=""
                                            />
                                          </div>
                                        </div>
                                      </Tooltip>
                                      {asset.name === "PIL_22_23_JUNE" ? (
                                        <>
                                          <Tooltip
                                            placement="right"
                                            title={"Click to add "}
                                          >
                                            <div
                                              className={` flex grow  hover:bg-[#ffca0094] py-1 rounded-md   items-center ${
                                                universalAsset ===
                                                "MLV_22_MLV_23_JUNE_DTM"
                                                  ? "bg-[#ffca0094]"
                                                  : showComparer === true
                                                  ? "bg-white"
                                                  : ""
                                              }`}
                                              key={index}
                                            >
                                              {showComparer && (
                                                <Checkbox
                                                  color="default"
                                                  size="small"
                                                  id={index}
                                                  checked={compareAssets.includes(
                                                    "MLV_22_MLV_23_JUNE_DTM"
                                                  )}
                                                  disabled={
                                                    compareAssets.includes(
                                                      "MLV_22_MLV_23_JUNE_DTM"
                                                    ) &&
                                                    compareAssets.length > 3
                                                  }
                                                  onChange={() =>
                                                    props.compareAssetsArray(
                                                      "MLV_22_MLV_23_JUNE_DTM"
                                                    )
                                                  }
                                                />
                                              )}

                                              <div
                                                className={`w-full  cursor-pointer px-2 pr-[10px] flex justify-between itmes-center   `}
                                                onClick={() =>
                                                  dispatch(
                                                    gisHomeActions.setAsset({
                                                      asset:
                                                        "MLV_22_MLV_23_JUNE_DTM",
                                                      assetType: "GEOSERVER",
                                                    })
                                                  )
                                                }
                                              >
                                                <p
                                                  className={`pl-4 ${
                                                    universalAsset ===
                                                    asset.name
                                                      ? null
                                                      : "border-l-2"
                                                  }`}
                                                >
                                                  {getLastWord(
                                                    "MLV_22_MLV_23_JUNE_DTM"
                                                  ).toUpperCase()}
                                                </p>

                                                <img
                                                  src={addIco}
                                                  className="h-5 border-2 my-auto border-[#808080] rounded-full"
                                                  alt=""
                                                  srcset=""
                                                />
                                              </div>
                                            </div>
                                          </Tooltip>
                                          <Tooltip
                                            placement="right"
                                            title={"Click to add "}
                                          >
                                            <div
                                              className={` flex grow  hover:bg-[#ffca0094] py-1 rounded-md   items-center ${
                                                universalAsset ===
                                                "MLV_22_MLV_23_JUNE_DSM"
                                                  ? "bg-[#ffca0094]"
                                                  : showComparer === true
                                                  ? "bg-white"
                                                  : ""
                                              }`}
                                              key={index}
                                            >
                                              {showComparer && (
                                                <Checkbox
                                                  color="default"
                                                  size="small"
                                                  id={index}
                                                  checked={compareAssets.includes(
                                                    "MLV_22_MLV_23_JUNE_DSM"
                                                  )}
                                                  disabled={
                                                    compareAssets.includes(
                                                      "MLV_22_MLV_23_JUNE_DSM"
                                                    ) &&
                                                    compareAssets.length > 3
                                                  }
                                                  onChange={() =>
                                                    props.compareAssetsArray(
                                                      "MLV_22_MLV_23_JUNE_DSM"
                                                    )
                                                  }
                                                />
                                              )}

                                              <div
                                                className={`w-full  cursor-pointer px-2 pr-[10px] flex justify-between itmes-center   `}
                                                onClick={() =>
                                                  dispatch(
                                                    gisHomeActions.setAsset({
                                                      asset:
                                                        "MLV_22_MLV_23_JUNE_DSM",
                                                      assetType: "GEOSERVER",
                                                    })
                                                  )
                                                }
                                              >
                                                <p
                                                  className={`pl-4 ${
                                                    universalAsset ===
                                                    asset.name
                                                      ? null
                                                      : "border-l-2"
                                                  }`}
                                                >
                                                  {getLastWord(
                                                    "MLV_22_MLV_23_JUNE_DSM"
                                                  ).toUpperCase()}
                                                </p>

                                                <img
                                                  src={addIco}
                                                  className="h-5 border-2 my-auto border-[#808080] rounded-full"
                                                  alt=""
                                                  srcset=""
                                                />
                                              </div>
                                            </div>
                                          </Tooltip>
                                        </>
                                      ) : null}
                                      {asset.name === "PIL_22_23_APRIL" ? (
                                        <>
                                          <Tooltip
                                            placement="right"
                                            title={"Click to add "}
                                          >
                                            <div
                                              className={` flex grow  hover:bg-[#ffca0094] py-1 rounded-md   items-center ${
                                                universalAsset ===
                                                "MLV_22_MLV_23_APRIL_DTM"
                                                  ? "bg-[#ffca0094]"
                                                  : showComparer === true
                                                  ? "bg-white"
                                                  : ""
                                              }`}
                                              key={index}
                                            >
                                              {showComparer && (
                                                <Checkbox
                                                  color="default"
                                                  size="small"
                                                  id={index}
                                                  checked={compareAssets.includes(
                                                    "MLV_22_MLV_23_APRIL_DTM"
                                                  )}
                                                  disabled={
                                                    compareAssets.includes(
                                                      "MLV_22_MLV_23_APRIL_DTM"
                                                    ) &&
                                                    compareAssets.length > 3
                                                  }
                                                  onChange={() =>
                                                    props.compareAssetsArray(
                                                      "MLV_22_MLV_23_APRIL_DTM"
                                                    )
                                                  }
                                                />
                                              )}

                                              <div
                                                className={`w-full  cursor-pointer px-2 pr-[10px] flex justify-between itmes-center   `}
                                                onClick={() =>
                                                  dispatch(
                                                    gisHomeActions.setAsset({
                                                      asset:
                                                        "MLV_22_MLV_23_APRIL_DTM",
                                                      assetType: "GEOSERVER",
                                                    })
                                                  )
                                                }
                                              >
                                                <p
                                                  className={`pl-4 ${
                                                    universalAsset ===
                                                    asset.name
                                                      ? null
                                                      : "border-l-2"
                                                  }`}
                                                >
                                                  {getLastWord(
                                                    "MLV_22_MLV_23_APRIL_DTM"
                                                  ).toUpperCase()}
                                                </p>

                                                <img
                                                  src={addIco}
                                                  className="h-5 border-2 my-auto border-[#808080] rounded-full"
                                                  alt=""
                                                  srcset=""
                                                />
                                              </div>
                                            </div>
                                          </Tooltip>
                                          <Tooltip
                                            placement="right"
                                            title={"Click to add "}
                                          >
                                            <div
                                              className={` flex grow  hover:bg-[#ffca0094] py-1 rounded-md   items-center ${
                                                universalAsset ===
                                                "MLV_22_MLV_23_APRIL_DSM"
                                                  ? "bg-[#ffca0094]"
                                                  : showComparer === true
                                                  ? "bg-white"
                                                  : ""
                                              }`}
                                              key={index}
                                            >
                                              {showComparer && (
                                                <Checkbox
                                                  color="default"
                                                  size="small"
                                                  id={index}
                                                  checked={compareAssets.includes(
                                                    "MLV_22_MLV_23_APRIL_DSM"
                                                  )}
                                                  disabled={
                                                    compareAssets.includes(
                                                      "MLV_22_MLV_23_APRIL_DSM"
                                                    ) &&
                                                    compareAssets.length > 3
                                                  }
                                                  onChange={() =>
                                                    props.compareAssetsArray(
                                                      "MLV_22_MLV_23_APRIL_DSM"
                                                    )
                                                  }
                                                />
                                              )}

                                              <div
                                                className={`w-full  cursor-pointer px-2 pr-[10px] flex justify-between itmes-center   `}
                                                onClick={() =>
                                                  dispatch(
                                                    gisHomeActions.setAsset({
                                                      asset:
                                                        "MLV_22_MLV_23_APRIL_DSM",
                                                      assetType: "GEOSERVER",
                                                    })
                                                  )
                                                }
                                              >
                                                <p
                                                  className={`pl-4 ${
                                                    universalAsset ===
                                                    asset.name
                                                      ? null
                                                      : "border-l-2"
                                                  }`}
                                                >
                                                  {getLastWord(
                                                    "MLV_22_MLV_23_APRIL_DSM"
                                                  ).toUpperCase()}
                                                </p>

                                                <img
                                                  src={addIco}
                                                  className="h-5 border-2 my-auto border-[#808080] rounded-full"
                                                  alt=""
                                                  srcset=""
                                                />
                                              </div>
                                            </div>
                                          </Tooltip>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null
                                )}
                              </ul>
                            </li>
                          ))
                        ) : (
                          <p className="pl-4">No timelines found</p>
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      {/* {PILDates?.map((asset, index) => (
            <div
              className={`box-showl flex grow    ${
                universalAsset === asset.name
                  ? "bg-[#f0f8ff]"
                  : showComparer === true
                  ? "bg-white"
                  : ""
              }`}
              key={index}
            >
              {showComparer && (
                <Checkbox
                  color="default"
                  size="small"
                  id={index}
                  checked={compareAssets.includes(asset.name)}
                  disabled={
                    compareAssets.includes(asset.name) &&
                    compareAssets.length > 3
                  }
                  onChange={() => props.compareAssetsArray(asset.name)}
                />
              )}
              <div
                className={`w-full border-b cursor-pointer py-2 px-2 flex justify-between   `}
                onClick={() =>
                  dispatch(
                    gisHomeActions.setAsset({
                      asset: asset.name,
                      assetType: "GEOSERVER",
                    })
                  )
                }
              >
                {getLastWord(asset.name)} 2023
              </div>
            </div>
          ))} */}
    </div>
  );
};

export default SectionsIndexing;
