import React from "react";
import Cookies from "js-cookie";
import { Checkbox, IconButton, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { dataJson } from "../../Data/Grp_table_data";
import { useRef, useState } from "react";
import {
  projectData,
  projectDataIOCL,
  AGPData,
  asslrData,
  MunicipalityData,
  landRecData,
  miningData,
} from "../../Data/testProjectData";
import { latLngs } from "../../Utils/lat_long";
import { latLngs_ct_dr } from "../../Utils/lat_long_csmt_dadar";
import { latLngs_iocl_nrpl } from "../../Utils/lat_long_iocl_nrpl";
import { latLngs_virar_dhu } from "../../Utils/lat_long_virar_dahanu";
import MapLine from "../../Components/Layouts/MapLine";
import ORIimage from "../../Assets/images/agp/output-onlinejpgtools.jpg";
import DunlopImage from "../../Assets/images/agp/dunlop_canal.png";
import ApsslrImage from "../../Assets/images/GIS/apsslrOri.png";
import KDMA from "../../Assets/images/KDMC.png";
import welc from "../../Assets/images/welcome.svg";
import ReportModal from "../../Components/GIS/ReportModal";
import DOITCImage from "../../Assets/images/GIS/doitc_image.png";
import { tokenChecker } from "../../Features/GIS/Utils/CookieUtils";
import { esselOri } from "../../Assets/images/servicesImg/services";
import { useDispatch } from "react-redux";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import axios from "axios";
import TimelineModal from "./TimelineModal";

const style = {
  height: "auto",
  width: 400,
  boxShadow: 24,
};

const setMap = (location) => {
  var maplocation;
  if (location === "Panvel") {
    maplocation = latLngs;
  } else if (location === "Virar") {
    maplocation = latLngs_virar_dhu;
  } else if (location === "IOCL") {
    maplocation = latLngs_iocl_nrpl;
  } else {
    maplocation = latLngs_ct_dr;
  }
  return maplocation;
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myElementRef = useRef(null);
  const cookieValue = Cookies.get("clientName");
  const userDetails = Cookies.get("userEmail");
  const [open, setOpen] = React.useState(false);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [compareMsg, setCompareMsg] = React.useState("");
  const [isCompare, setIsCompare] = React.useState(false);
  const [summeryView, SetsummeryView] = React.useState(false);
  const [elementWidth, setElementWidth] = React.useState(null);
  const [clientProjects, setClientProjects] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState([]);
  const [openProjectIndex, setOpenProjectIndex] = React.useState(-1);
  const [checkedCheckboxes, setCheckedCheckboxes] = React.useState([]);
  const [welcomeModalOpen, setWelcomeModalOpen] = React.useState(false);
  const [openSummaryModalIndex, setOpenSummaryModalIndex] = useState(-1);

  var projects, prjImg, clientId;
  if (cookieValue === "oil") {
    projects = projectDataIOCL;
    clientId = 8;
  } else if (cookieValue === "infra") {
    projects = AGPData;
    prjImg = ORIimage;
  } else if (cookieValue === "mnc") {
    projects = MunicipalityData;
    prjImg = DunlopImage;
  } else if (cookieValue === "scity") {
    projects = asslrData;
    prjImg = DOITCImage;
  } else if (cookieValue === "lrec") {
    projects = landRecData;
    prjImg = ApsslrImage;
  } else if (cookieValue === "mine") {
    projects = miningData;
    prjImg = esselOri;
  } else {
    projects = projectData;
    clientId = 7;
  }

  React.useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  React.useEffect(() => {
    const getLogin = Cookies.get("firstLogin");
    var getLoginValue = parseInt(getLogin);
    if (getLoginValue === 1) {
      setWelcomeModalOpen(true);
    } else {
      setWelcomeModalOpen(false);
    }
    Cookies.set("firstLogin", getLoginValue + 1);

    dispatch(
      gisHomeActions.setAsset({
        asset: "",
        assetType: "",
      })
    );

    dispatch(gisActions.latestAssetCounter("payload"));
  }, []);

  React.useEffect(() => {
    const getProjects = async () => {
      if (clientId) {
        const response = await axios.get(
          `https://delivery.inspect.indrones.com/api/v1/project/${clientId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (userDetails === "arpan.kumar@gmail.com") {
          const csmtData = response.data.filter((obj) => obj.id === 8);
          setClientProjects(csmtData);
        } else {
          setClientProjects(response.data);
        }
      } else {
        setClientProjects(projects);
      }
    };
    getProjects();
  }, [clientId, projects]);

  const handleCompare = (index, project) => {
    setIsCompare(true);
    setOpenProjectIndex(index);
    setSelectedProject(project);
    setOpen(true);
  };

  const handleSummeryOpen = (event, index) => {
    setOpenSummaryModalIndex(index);
    SetsummeryView(true);
  };
  const handleSummeryClose = () => {
    SetsummeryView(false);
  };

  const handleOpen = (index, project) => {
    setIsCompare(false);
    setOpenProjectIndex(index);
    setSelectedProject(project);
    setOpen(true);
  };

  const handleViewbtn = (event, index) => {
    setOpenProjectIndex(index);
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setCheckedCheckboxes([]);
    if (isCompare) {
      setIsCompare(false);
      setCompareMsg("");
    }
  };
  const handleWelcomeClose = () => {
    setWelcomeModalOpen(false);
  };

  React.useEffect(() => {
    dispatch(
      gisHomeActions.setAsset({
        asset: null,
        assetType: "TERRAIN",
      })
    );
  }, []);

  React.useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const openPdf = () => {
    // console.log("open");
    const newTab = window.open(
      "https://indrones-public.s3.ap-south-1.amazonaws.com/sample_report.pdf",
      "_blank"
    );
    newTab.focus();
  };

  return (
    <div className="flex flex-col w-full overflow-y-scroll custom-scrollbar no-track relative  sidebr-project-dashbord">
      <Modal
        open={welcomeModalOpen}
        onClose={handleWelcomeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center"
      >
        <div
          style={style}
          className="flex animatformodel flex-col items-center max-[600px]:m-[1%] justify-between bg-white !w-[850px]  h-[30%] rounded-lg"
        >
          <div className="flex items-center justify-end w-[850px] max-[600px]:w-[100%] p-2 absolute">
            {/* <h3 className="!text-[1.3rem] text-[#211f1c] grow font-bold text-center">
                Welcome to Inspect
              </h3> */}
            <IconButton
              onClick={handleWelcomeClose}
              aria-label="Close"
              size="small"
              className="text-[#211f1c] text-[13px]"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex max-[600px]:flex-col items-center max-[600px]:p-[2%]">
            <div className="flex-1">
              <img className="w-[100%] p-[8%]" src={welc} alt="ERROR!" />
            </div>
            <div className="w-full text-justify flex flex-col flex-1 m-[38px] break-word">
              <h2 className="!text-[40px] tracking-[1px] font-semibold text-[#4a4844] p-[0px] mb-[10px]">
                Welcome to <span className="!text-[#ffc700]">Inspect</span>
              </h2>
              <h3>
                Track operations, assess effectiveness, examine, and download
                curated reports, personalised for your workflows.
              </h3>
            </div>
          </div>
        </div>
      </Modal>
      <div className="grow grid gap-[12px] p-[15px] max-[767px]:p-[6px] grid-cols-3 max-[500px]:grid-cols-1 max-[500px]:gap-[8px] grid-rows-3 h-fit tabletview">
        {clientProjects.map((project, index) => {
          const isSelected = openProjectIndex === index;
          const locationString = project.end_location
            ? `${project.start_location}-${project.end_location}`
            : `${project.start_location}`;
          return (
            <>
              <div
                className="w-[100%] flex flex-col mt-[0px] rounded-[3px] relative bg-[#fff] p-2 h-full"
                key={index}
              >
                {!project.nonLinear ? (
                  <>
                    <div
                      className="viewbtnformap cursor-pointer"
                      onClick={(event) => handleViewbtn(event, index)}
                    >
                      <RemoveRedEyeOutlinedIcon
                        className="hover:text-[#4a4844]"
                        style={{ fontSize: 18 }}
                      />
                    </div>
                    <div className="w-[100%] h-[250px] z-[1]">
                      <MapLine
                        location={setMap(project.start_location)}
                        modalView={false}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-[100%] h-[250px] z-[1]">
                      <img
                        src={project.projectName == "kdmc" ? KDMA : prjImg}
                        alt="Error"
                        className="w-[100%] h-[100%]"
                      />
                    </div>
                  </>
                )}
                <div className="bg-[#fff] detailscardproject h-auto mt-2">
                  <div className="flex  justify-between items-center">
                    <div className="font-semibold uppercase text-[17px]	tracking-[1px]">
                      {locationString}
                    </div>
                    <div className="typesOf">{project.project_type}</div>
                  </div>
                  <div className="border-b border-solid border-[#f1efef] mt-[6px] mb-[6px]"></div>
                  {!project.nonLinear && (
                    <>
                      <div className="flex justify-between text-[15px] max-[395px]:flex-col">
                        <div className="flex">
                          <h2 className="font-[600] w-[120px] text-[#757576]">
                            Start Chainage :
                          </h2>
                          <span className="ml-[5px] text-[#7f8183]">
                            {project.start_chainage} km
                          </span>
                        </div>
                        <div className="flex">
                          <h2 className="font-[600] w-[120px] text-[#757576]">
                            End Chainage :
                          </h2>
                          <span className="text-[#7f8183]">
                            {project.end_chainage} km
                          </span>
                        </div>
                      </div>
                      <div className="border-b border-solid border-[#f1efef] mt-[6px] mb-[11px]"></div>
                    </>
                  )}
                  <div className="flex flex-row justify-between mt-2">
                    <div
                      onClick={openPdf}
                      className="flex items-center justify-center rounded-[5px] cursor-pointer border-[1px] border-[#4a4844] padding-btn2 "
                    >
                      <span className="capitalize text-[14px] font-[400] m-[3px] tracking-[0.9px]">
                        Project Summary
                      </span>
                    </div>
                    <div className="flex">
                      <div
                        className="flex items-center justify-center rounded-[5px] cursor-pointer  play-btn"
                        onClick={
                          project.nonLinear
                            ? () => navigate(`/gis/${project.path}`)
                            : (event) => handleOpen(index, project.id)
                        }
                      >
                        <span className="capitalize text-[14px] font-[400] m-[3px] tracking-[0.9px]">
                          Evaluate
                        </span>
                      </div>
                      {!project.nonLinear && (
                        <div
                          className="flex items-center justify-center rounded-[5px] cursor-pointer Compare-btn ml-[10px]"
                          onClick={(event) => handleCompare(index, project.id)}
                        >
                          <span>Compare</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {summeryView && openSummaryModalIndex === index && (
                <ReportModal
                  onClose={handleSummeryClose}
                  dataJson={dataJson}
                  elementWidth={elementWidth}
                  type="IOCL-NRPL"
                />
              )}
              {isSelected && (
                <>
                  <TimelineModal
                    project={selectedProject}
                    open={open}
                    handleClose={handleClose}
                    isCompare={isCompare}
                  />
                  {/* <Modal
                    open={open}
                    onClose={handleClose}
                    className="flex justify-center items-center"
                  >
                    <div
                      style={style}
                      className="rounded-[12px] flex flex-col items-center justify-between bg-white w-full h-min"
                    >
                      <div className="flex items-center justify-between bg-[#ffca0094] mb-[8px] rounded-tl-[12px] rounded-tr-[12px] w-full pt-[10px] pb-[10px] font-[500] border-b">
                        <h3 className="!text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
                          Timeline
                        </h3>
                        <div className="mr-[10px]">
                          <IconButton
                            onClick={handleClose}
                            aria-label="Close"
                            size="small"
                            className="text-[#211f1c]"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                      {project.active ? (
                        <div className="grow w-full p-3 pb-2 pt-0">
                          {project.fly_dates.map((fly, index) => {
                            return (
                              <div
                                key={index}
                                className="flex w-[100%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                              >
                                {isCompare && (
                                  <Checkbox
                                    {...label}
                                    color="default"
                                    size="small"
                                    id={`checkbox${index}`}
                                    disabled={fly.isCompare ? false : true}
                                    onChange={() =>
                                      handleCheckboxChange(index, fly.id)
                                    }
                                  />
                                )}
                                <div
                                  className={`grow ${
                                    fly.data && fly.isCompare
                                      ? "text-black"
                                      : fly.data && !isCompare
                                      ? "text-black"
                                      : "text-gray-200"
                                  }`}
                                >
                                  {fly.date}
                                </div>
                                {!isCompare && (
                                  <div>
                                    <button
                                      className={`text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] ${
                                        fly.data
                                          ? ""
                                          : "opacity-50 cursor-no-drop"
                                      }`}
                                      onClick={
                                        fly.data
                                          ? () => {
                                              handleFly(fly.id);
                                            }
                                          : () => {}
                                      }
                                    >
                                      Go
                                    </button>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="h-[100px] mt-5">No Data Available</div>
                      )}
                      {isCompare && (
                        <>
                          <div
                            id="errorText"
                            className="mb-3 text-[#ff0000] text-center text-sm font-medium"
                          >
                            {compareMsg}
                          </div>
                          {project.active && (
                            <div className="flex items-center mb-[20px]">
                              <button
                                onClick={handleCompareScreen}
                                className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                              >
                                Go
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Modal>*/}
                  <Modal
                    open={viewOpen}
                    onClose={handleViewClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="flex justify-center items-center"
                  >
                    <div className="h-[500px] w-[900px] bg-white relative rounded-[12px]">
                      <div className="flex items-center justify-between bg-[#ffca0094] rounded-tl-[12px] rounded-tr-[12px] w-full pt-[10px] pb-[10px] font-[500] border-b">
                        <h3 className="!text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
                          {project.start_location}-{project.end_location}
                        </h3>
                        <div className="mr-[10px]">
                          <IconButton
                            onClick={handleViewClose}
                            aria-label="Close"
                            size="small"
                            className="text-[#211f1c]"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                      <MapLine
                        location={setMap(project.start_location)}
                        modalView={true}
                      />
                    </div>
                  </Modal>
                </>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
