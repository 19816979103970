import React from "react";
import { Avatar, Divider, IconButton, TextField } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "antd";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CommentManager } from "../../Services/ThirdPartyApi/AWS/DynamoDB";
// import { getUserId, getTokenFromCookie } from "../../utils/CookieUtils";

import axios from "axios";
import { formatDate } from "../../Utils/OtherUtils";

import { getUserId, getTokenFromCookie } from "../../Utils/Cookie";

const CommentBox = (props) => {
  const [comment, setComment] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [commentArray, setCommentArray] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [editComment, setEditComment] = React.useState("");
  // const commentApi = new CommentManager("ori_annotations");

  const getUserDetails = async () => {
    try {
      const response = await axios.get(
        `https://staging.incharge.indrones.com/get-user-detail/${getUserId()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenFromCookie()}`,
          },
        }
      );
      return response.data.user_obj;
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    const getComments = async () => {
      const comments = await CommentManager.getCommentsByMarkerID(props.id);
      setCommentArray(comments);
    };
    getComments();
  }, [props.id]);

  const addComment = async () => {
    if (comment === "") {
      setShowError(true);
    } else {
      setShowError(false);
      const newTimestamp = Date.now();
      const userDetails = await getUserDetails();
      const dataToPush = {
        id: uuidv4(),
        timestamp: newTimestamp,
        markerID: props.id,
        createdBy: userDetails,
        comment: comment,
      };
      try {
        CommentManager.addComments(dataToPush);
        setCommentArray([dataToPush, ...commentArray]);
        setComment("");
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleEdit = (index, comment) => {
    setEditIndex(index);
    setEditMode(!editMode);
    setEditComment(comment);
  };

  const handleDelete = async (index, comment) => {
    const updatedList = commentArray.filter(
      (comments) => comments.id !== comment.id
    );
    try {
      CommentManager.deleteComment(comment.id, comment.timestamp);
      setCommentArray([...updatedList]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCommentEdit = async (commentId, timestamp) => {
    const updatedList = commentArray.filter(
      (comment) => comment.id !== commentId
    );
    try {
      const updatedItem = await CommentManager.updateCommentById(
        {
          id: commentId,
          timestamp: timestamp,
        },
        editComment
      );
      setCommentArray([updatedItem, ...updatedList]);
      setEditMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col m-3">
      <div className="w-full flex items-center gap-2 justify-center mb-2">
        <div className="w-[80%]">
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            placeholder="Add a new Comment"
            required
          />
        </div>
        <div className="h-full" onClick={addComment}>
          <Button variant="contained">Add</Button>
        </div>
      </div>
      {showError && (
        <div className="text-red-500 text-xs mx-[30%] shake">
          comment can't be empty
        </div>
      )}
      <div className="flex flex-col">
        {commentArray.map((comment, index) => {
          return (
            <div
              key={index}
              className="flex flex-col border rounded-lg px-3 py-2 mt-3"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 items-center mb-2">
                  <Avatar
                    src="/broken-image.jpg"
                    sx={{
                      width: 24,
                      height: 24,
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                  <div className="flex flex-col">
                    <span className="h-min">{`${comment.createdBy.first_name} ${comment.createdBy.last_name}`}</span>
                    <div className="text-sm relative top-[-5px]">
                      {formatDate(comment.timestamp)}
                    </div>
                  </div>
                </div>
                {comment.createdBy.id === parseInt(getUserId()) && (
                  <div className="h-full mb-auto">
                    <IconButton
                      aria-label="edit"
                      // sx={{ marginBottom: "auto" }}
                      onClick={() => handleEdit(index, comment.comment)}
                    >
                      <EditIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      // sx={{ marginBottom: "auto" }}
                      onClick={() => handleDelete(index, comment)}
                    >
                      <DeleteIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </div>
                )}
              </div>
              <Divider />
              {editMode && editIndex === index ? (
                <div className="flex items-center mt-2 gap-2">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={editComment}
                    onChange={(e) => setEditComment(e.target.value)}
                    fullWidth
                    placeholder="Add a new Comment"
                    required
                  />
                  <Button
                    variant="contained"
                    endIcon={<AddCircleIcon />}
                    onClick={() =>
                      handleCommentEdit(comment.id, comment.timestamp)
                    }
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div className="mt-2">{comment.comment}</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CommentBox;
