import React, { useState, useEffect, useRef } from "react";
import "../../Assets/styles/PipelineInfra.css";
import {
  Cartesian3,
  Ion,
  Viewer,
  Math as MathOfCesium,
  VerticalOrigin,
} from "cesium";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import { FormControl } from "@mui/base";

import { useDispatch } from "react-redux";

import { yellowPin } from "../../Assets/icons/gisIcons/icons";
import {
  indronesNameLogoBlack,
  pilLogo,
} from "../../Assets/images/gisImg/images";

import Cookies from "js-cookie";
import { validEmail } from "./Regex";

import { removeAllCookies } from "../../Utils/Cookie";
import { InchargeApis } from "../../Services/SecondPartyApi/Incharge";
import { userActions } from "../../redux/slices/user-slice";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";

const PipelineInfraLogin = () => {
  const viewerRef = useRef(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [MailAlertMge, setMailAlertMge] = React.useState("");
  const [PassAlertMge, setPassAlertMge] = React.useState("");
  const dispatch = useDispatch();
  const cookieValue = Cookies.get("clientName");

  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNzhkZjc0ZS02YzQzLTRjMzAtODg3ZC0zOTk4MzFlMWRmNzgiLCJpZCI6MTU5MTU1LCJpYXQiOjE2OTIyODIwODV9._OhEfJPn8nLJ6LwuOSd7-Z3MB8MpQT_FwyxIOnWL2cI";

  useEffect(() => {
    try {
      Ion.defaultAccessToken = token;

      if (!viewerRef.current) {
        viewerRef.current = new Viewer("cesiumContainer", {
          baseLayerPicker: false,
          timeline: false,
          homeButton: false,
          sceneModePicker: false,
          navigationHelpButton: false,
          fullscreenButton: false,
          animation: false,
          creditContainer: null,
        });
      }

      const flyToIndia = () => {
        const indiaCoordinates = Cartesian3.fromDegrees(
          73.01709506626335,
          19.022013496159975,
          360000
        );

        // Fly to India with a smooth animation
        viewerRef.current.camera.flyTo({
          destination: indiaCoordinates,
          orientation: {
            heading: 0,
            pitch: -MathOfCesium.PI_OVER_TWO,
            roll: 0,
          },
          duration: 5,
        });
      };

      const markerPosition = Cartesian3.fromDegrees(
        73.01709506626335,
        19.022013496159975
      );

      const addMarker = () => {
        viewerRef.current.entities.add({
          position: markerPosition,
          billboard: {
            image: yellowPin,
            verticalOrigin: VerticalOrigin.BOTTOM,
            height: 40,
            width: 40,
          },
        });
      };

      // Set a timeout to trigger the flyToIndia function after 1 second
      const timeoutId = setTimeout(flyToIndia, 1000);
      const timeoutLogo = setTimeout(addMarker, 1500);

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    } catch (error) {
      console.error("Error initializing Cesium:", error);
    }
  }, []);

  useEffect(() => {
    dispatch(
      gisHomeActions.setAsset({
        asset: "",
        assetType: "",
      })
    );
    dispatch(gisHomeActions.setShapesIds([]));
  }, []);

  const params = {};
  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.email.value;
    const password = event.target.password.value;
    params.username = username;
    params.password = password;
    params.user_app = "Inspect";
    if (username !== "") {
      const { token, userDetails, status } =
        await InchargeApis.authenticateUser(params);
      if (
        userDetails.email === "arpan.kumar@gmail.com" &&
        cookieValue !== "rail"
      ) {
        alert("You are not allowed to view this service!!!");
      } else if (status === 200) {
        const { access, refresh } = token;
        Cookies.set("access_token", access);
        dispatch(userActions.setUser(userDetails));
        Cookies.set("userId", userDetails.user_id);
        Cookies.set("userEmail", userDetails.email);
        if (cookieValue === "hway") {
          navigate("/nhai");
        } else {
          navigate("/gis/PIL");
        }
      } else if (status === 404) {
        var userid = toast.error("User not found!");
      } else {
        var wrongid = toast.error("Wrong password!");
      }
    }

    if (username === "") {
      toast.remove(wrongid);
      toast.remove(userid);
      setMailAlertMge("Please enter your email");
    } else if (!validEmail.test(email)) {
      toast.remove(wrongid);
      toast.remove(userid);
      setMailAlertMge("Your email is invalid");
    }
    // password conditions
    if (password === "") {
      toast.remove(wrongid);
      toast.remove(userid);
      setPassAlertMge("Please enter your password");
    } else if (password.length <= 3) {
      toast.remove(wrongid);
      toast.remove(userid);
      setPassAlertMge("Please enter corrent password");
    }
  };
  const handleEmailChange = (e) => {
    setMailAlertMge("");
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassAlertMge("");
    setPassword(e.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <div className=" overflow-hidden">
      <div id="cesiumContainer" className="h-[100vh] pipelineInfraCesium">
        <div className="glass-container flex flex-col justify-center items-center h-auto min-w-[22vw]  px-1 lg:px-4 pb-20 pt-12 rounded-lg absolute top-1/2 left-[10%] transform -translate-y-1/2 bg-white  shadow-lg z-50 ">
          <div className="flex justify-center items-end my-4  ">
            <img className="h-12 w-auto" src={pilLogo} alt="pil logo" />{" "}
          </div>
          {/* <h2 className="my-4 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in
          </h2> */}
          <form className="mt-8 w-5/6 " onSubmit={handleSubmit}>
            <div className="mb-6 relative">
              <TextField
                id="outlined-Email-input"
                name="email"
                className="w-full"
                variant="outlined"
                label="Email"
                autoComplete="email"
                value={email}
                type="text"
                size="small"
                onChange={handleEmailChange}
                style={{ fontSize: "17px" }}
              />
              <div
                className={`text-[#ff0000] text-[11px] font-500 mt-[2px] absolute left-0`}
              >
                {MailAlertMge}
              </div>
              <div className="loginpage">
                {<AlternateEmailRoundedIcon sx={{ fontSize: 18 }} />}
              </div>
            </div>
            <div className="mb-2 relative">
              <FormControl fullWidth size="small">
                <TextField
                  id="outlined-password-input"
                  className="w-full"
                  variant="outlined"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  size="small"
                  autoComplete="current-password"
                  // value={password}
                  onChange={handlePasswordChange}
                  style={{ fontSize: "17px" }}
                />
              </FormControl>
              <div
                className={`text-[#ff0000] text-[11px] mt-[2px] font-500 absolute left-0`}
              >
                {PassAlertMge}
              </div>

              <div className="loginpage" onClick={handleClickShowPassword}>
                {showPassword ? (
                  <VisibilityRoundedIcon
                    className="cursor-pointer"
                    sx={{ fontSize: 18 }}
                  />
                ) : (
                  <VisibilityOffRoundedIcon
                    className="cursor-pointer"
                    sx={{ fontSize: 18 }}
                  />
                )}
              </div>
            </div>
            {/* <div className="text-[#4a4844] font-[600] text-[12px] text-right cursor-pointer">
              Forgot Password
            </div> */}
            <div className="mt-8  mx-auto">
              <button
                className="padding-[13px] items-center h-[42px] flex w-full justify-center rounded-[3px] bg-[#4a4844] px-3 py-1.5 text-[16px] font-semibold leading-6 text-white shadow-sm hover:bg-[#808787] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                type="submit"
              >
                Login
              </button>
            </div>
            <Toaster
              position="top-center"
              reverseOrder={true}
              toastOptions={{
                duration: 2000,
                style: {
                  background: "#fff",
                  color: "#4a4844",
                  border: "1px solid #fff",
                },
              }}
            />
          </form>
          <div className="flex items-center ml-2 absolute bottom-2">
            <span className=" text-sm">Powered by</span>
            <img
              src={indronesNameLogoBlack}
              className="h-4"
              alt="indrones name logo black"
              srcSet=""
            />
          </div>
        </div>
      </div>

      {/* Login Modal */}
      {/* {isModalOpen && ( */}

      {/* )} */}
    </div>
  );
};

export default PipelineInfraLogin;
