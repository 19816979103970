import React, { useState, useEffect, useRef, useId } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AnnotationReport } from "./AnnotationReport";
import {
  pilObservationCategories,
  pilObservations,
} from "../../Data/config/pilData";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import {
  icon,
  infoIco,
  visibilityIco,
  visibilityOffIco,
} from "../../Assets/icons/gisIcons/icons";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import CesiumUtils from "../../Utils/CesiumUtils";
import GISUtils from "../../Utils/GISUtils";

const Annotations = ({ viewer, openReport }) => {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [openAnnoReport, setOpenAnnoReport] = useState(null);
  const severities = ["All", "Low", "Medium", "High"];
  const [assetFilter, setAssetFilter] = useState(null);
  const myElementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(null);
  const assetId = useSelector((state) => state.gisHome.asset);
  const { filteredAndInheritedAnnotations } = useSelector((state) => state.gis);
  const [finalFilteredAnnotations, setFinalFilteredAnnotations] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (assetId) {
      setAssetFilter(assetId);
    }
  }, [assetId]);

  useEffect(() => {
    setFinalFilteredAnnotations(
      categoryFilter === "" && severityFilter === ""
        ? filteredAndInheritedAnnotations
        : filteredAndInheritedAnnotations.filter(
            (observation) =>
              (categoryFilter === "" ||
                observation.category === categoryFilter) &&
              (severityFilter === "" || observation.severity === severityFilter)
          )
    );
  }, [filteredAndInheritedAnnotations, categoryFilter, severityFilter]);

  console.log(finalFilteredAnnotations, "final filtered obs");

  const handleReportClick = (annotation) => {
    setOpenAnnoReport(true);
  };

  const handleCloseModal = () => {
    setOpenAnnoReport(null);
  };

  const handleChange = (name) => {
    if (name === "Low") {
      return "bg-yellow-200 border border-yellow-500 text-black";
    } else if (name === "Medium") {
      return "bg-orange-200 border border-orange-500 text-black";
    } else if (name === "High") {
      return "bg-red-200 border border-red-500 text-black";
    } else {
      return "bg-purple-200 border border-purple-500 text-black";
    }
  };

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const handleCheckboxChange = (id) => {
    const updatedAnnotations = finalFilteredAnnotations.map((annotation) =>
      annotation.id === id
        ? { ...annotation, visible: !annotation.visible }
        : annotation
    );
    // setFinalFilteredAnnotations(updatedAnnotations);
    dispatch(gisActions.setAllObservations(updatedAnnotations));
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function toNormalCase(inputString) {
    return inputString.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
  }

  const idElementRender = useId();

  return (
    <>
      <div
        ref={myElementRef}
        className={`${
          openReport ? "block" : "hidden"
        }  rounded-[2px] w-[275px] resize-able !grow h-[100%] mr-2 ml-2 mb-2 mt-1 border-solid border-[#d7d8d8] border-[1px] bg-[#fff]`}
      >
        <div className="w-full rounded-tl-[1px] rounded-tr-[1px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          <div className="grow flex items-center justify-center">
            Observations
          </div>
        </div>
        <div className="flex gap-2 flex-col p-2 my-2 items-center overflow-hidden">
          <div className="flex flex-col w-full">
            <label className="text-sm">Category</label>

            <select
              className="border border-gray-400 rounded text-xs min-h-[1.5rem] "
              value={categoryFilter}
              onChange={(e) =>
                e.target.value === "All"
                  ? setCategoryFilter("")
                  : setCategoryFilter(e.target.value)
              }
            >
              <option value="All">All</option>
              {pilObservationCategories.map((category, index) => (
                <option key={idElementRender + index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-full">
            <label className="text-sm">Risk </label>
            <select
              className="border border-gray-400 rounded text-xs m-h-[1.5rem]"
              value={severityFilter}
              onChange={(e) => {
                e.target.value === "All"
                  ? setSeverityFilter("")
                  : setSeverityFilter(e.target.value);
              }}
            >
              {severities.map((severity) => (
                <option key={idElementRender + severity} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
        </div>

        {finalFilteredAnnotations.length > 0 && assetId ? (
          <ul className="p-2 overflow-auto">
            {finalFilteredAnnotations?.map((annotation, index) => (
              <>
                <li
                  key={index}
                  className="my-2 rounded-md flex justify-between items-center gap-1"
                >
                  <input
                    type="checkbox"
                    name={`checkbox-${index}`}
                    checked={annotation.visible}
                    className=" focus:ring-transparent rounded-md "
                    onChange={() => handleCheckboxChange(annotation.id)}
                  />

                  {/* <img
                    src={annotation?.visible ? visibilityIco : visibilityOffIco}
                    onClick={() => handleCheckboxChange(annotation.id)}
                    alt={
                      annotation?.visible ? "visibilityIco" : "visibilityOffIco"
                    }
                    srcSet=""
                  /> */}
                  <h2
                    className={`text-base w-1/2 text-left cursor-pointer  `}
                    onClick={() => {
                      if (annotation.filter_type !== "marker") {
                        const shapeCordLen = annotation?.shapeCord.length;

                        CesiumUtils.handleFlyToMarkerUtil(
                          viewer,
                          annotation?.filter_type,
                          annotation?.shapeCord[shapeCordLen - 1]
                        );
                      } else {
                        CesiumUtils.handleFlyToMarkerUtil(
                          viewer,
                          annotation.filter_type,
                          {
                            longitude: annotation.longitude,
                            latitude: annotation.latitude,
                          }
                        );
                      }
                    }}
                  >
                    {annotation.typeName}
                  </h2>

                  <p
                    className={`text-xs border-1 p-1 px-2 border-slate-400 rounded-md  inline-block text-[#fff] 
                  ${handleChange(annotation.severity)} 
                  ${
                    annotation.category === "Polygon"
                      ? "cursor-none"
                      : "cursor-pointer"
                  }`}
                  >
                    {annotation?.category}
                  </p>
                  <div className="relative">
                    <HtmlTooltip
                      title={
                        <div className=" rounded-md">
                          <p>
                            Task Status :<b>{annotation?.taskStatus}</b>
                          </p>
                          <p>
                            Type Of Marker :{" "}
                            <b>{toNormalCase(annotation?.filter_type)}</b>
                          </p>
                          <p>
                            Timeline :{" "}
                            <b>
                              {GISUtils.getTimelineTitle(annotation?.timeline)}
                            </b>
                          </p>
                        </div>
                      }
                      placement="right"
                      arrow
                    >
                      <img
                        src={infoIco}
                        alt="Info"
                        className="cursor-pointer"
                        // onClick={() => handleInfoClick(annotation)}
                      />
                    </HtmlTooltip>
                  </div>
                  {/* <img src={infoIco} alt="" srcset="" /> */}
                </li>
              </>
            ))}
          </ul>
        ) : (
          <div className="text-center text-[12px] pb-3">
            {finalFilteredAnnotations.length > 0
              ? " No Annotations with Selected Filters"
              : "Please select asset from timeline."}
          </div>
        )}
        <div className="text-[12px] w-full flex pb-2 ">
          <span className="ml-auto pr-2">
            Number of Annotations: {finalFilteredAnnotations.length}
          </span>
        </div>
      </div>
      {openAnnoReport && (
        <AnnotationReport
          onClose={handleCloseModal}
          elementWidth={elementWidth}
          selectedAsset={assetFilter}
          selectedCategory={categoryFilter}
          selectedSeverity={severityFilter}
        />
      )}
    </>
  );
};

export default Annotations;
