import { createSlice } from "@reduxjs/toolkit";

const gisHomeSlice = createSlice({
  name: "gis-home",
  initialState: {
    // viewerRef: null,

    asset: null,
    assetType: "TERRAIN", // for imagery and 3d data
    compareAssets: [], // show compare tool toggle
    shapesIds: [], //selectedShapesIds
    bottomReportToggle: false,
    selectedTimeline: "",
  },

  reducers: {
    setAsset: (state, action) => {
      state.asset = action.payload.asset;
      state.assetType = action.payload.assetType;
    },
    setCompareAssets: (state, action) => {
      state.compareAssets = action.payload;
    },
    // shape files part (geojson)
    setShapesIds: (state, action) => {
      state.shapesIds = action.payload;
    },
    addShapeId: (state, action) => {
      state.shapesIds = [...state.shapesIds, action.payload];
    },
    removeShapeId: (state, action) => {
      const assetId = action.payload;
      state.shapesIds = state.shapesIds.filter((id) => id !== assetId);
    },
    bottomReportToggle: (state) => {
      state.bottomReportToggle = !state.bottomReportToggle;
    },
    setSelectedTimeline: (state, action) => {
      state.selectedTimeline = action.payload;
    },
  },
});

export const gisHomeActions = gisHomeSlice.actions;

export default gisHomeSlice.reducer;
