import { Cartesian3 } from "cesium";

/**
 * Handle the home button click event to reset the view in the  viewer.
 *
 * @param {React.RefObject} viewerRef - A React ref object pointing to the Cesium viewer component.
 * @param {function} setGoHome - A function to set the "goHome" state.
 * @param {boolean} goHome - A boolean state indicating whether to go to the home view.
 * @returns {void}
 * @version 1.0.0
 */
export const handleHomeButtonClick = (
  viewerRef,
  setGoHome,
  goHome,
  imageryLayer
) => {
  setGoHome(!goHome);
  const viewer = viewerRef.current;

  viewer.camera.flyTo({
    destination: Cartesian3.fromDegrees(
      75.05051035457586,
      18.494880619396216,
      3000000
    ),
  });
};
