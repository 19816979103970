import { useState, useEffect, useRef } from "react";
import MetaInput from "./MetaInput";
import {
  Viewer,
  Ion,
  IonImageryProvider,
  CzmlDataSource,
  ScreenSpaceEventType,
  defined,
  Math as MathOfCesium,
  ColorMaterialProperty,
  Color,
  Cartesian3,
  WebMapServiceImageryProvider,
  HorizontalOrigin,
  VerticalOrigin,
  Cartesian2,
  BoundingSphere,
  ScreenSpaceEventHandler,
  Cartographic,
  NearFarScalar,
  PolylineOutlineMaterialProperty,
  EntityCollection,
  Entity,
  GridMaterialProperty,
} from "cesium";
import MetaDataModal from "./MetaDataModal";
import VodBox from "./VodBox";
import VideoModal from "./VideoModal";
import {
  buildingIconHigh,
  buildingIconLow,
  buildingIconMedium,
  cctvCamBillBoardImg,
  chevronIco,
  customBillboardImg,
  treeIconHigh,
} from "../../Assets/icons/gisIcons/icons";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../../Features/GIS/Utils/otherUtils";
import GISToolbar from "./GISToolbar";
import { getDefaultPoints } from "../../Features/GIS/Utils/DefaultPoints";
import { useCompareAssets } from "../../Features/GIS/hooks/useCompareAssets";
import { useGeoJsonOnMap } from "../../Features/GIS/hooks/useGeoJsonOnMap";
import { useCzml } from "../../Features/GIS/hooks/useCzml";
import { handleHomeButtonClick } from "../../Features/GIS/Utils/GoHome";
import { usePolygonAnnotationTool } from "../../Features/GIS/hooks/usePolygonAnnotation";
import { usePolylineAnnotationTool } from "../../Features/GIS/hooks/usePolylineAnnotation";
import PolygonAnnotationModal from "./PolygonAnnotationModal";
import {
  AnnotationManager,
  DynamoDBApis,
} from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { useSelector, useDispatch } from "react-redux";
import { gisToolActions } from "../../redux/slices/GIS/gis-tools-slice";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import { getDate } from "../../Utils/OtherUtils";
import { S3Apis } from "../../Services/ThirdPartyApi/AWS/S3";
import PolylineAnnotationModal from "./PolylineAnnotationModal";
import { usePolylineMeasureTool } from "../../Features/GIS/hooks/usePolylineMeasureTool";
import { usePolygonMeasureTool } from "../../Features/GIS/hooks/usePolygonMeasureTool";
import { useLineMeasureTool } from "../../Features/GIS/hooks/useLineMeasureTool";
import { usePointMarkerAnnotation } from "../../Features/GIS/hooks/usePointMarkerAnnotation";
import { CSStationsList, PILDates } from "../../Data/config/pilData";
import GISUtils from "../../Utils/GISUtils";
import axios from "axios";

const getTimestamp = (idToFind, arrayOfObjects, timestamp) => {
  const resultObject = arrayOfObjects.find((obj) => obj.id === idToFind);
  if (timestamp) {
    return resultObject?.timestamp;
  } else {
    return resultObject;
  }
};

const newTimestamp = Date.now();

const GIS = ({ token, setLoader, viewerRef, grp, setAnnotation }) => {
  Ion.defaultAccessToken = token;
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [isModalOpenCustom, setIsModalOpenCustom] = useState(false);
  const [isMetaModalOpen, setIsMetaModalOpen] = useState(false);
  const [vodOpen, setVodOpen] = useState(false);
  const [hlsOpen, setHlsOpen] = useState(false);
  const [source, setSource] = useState(null);
  const [isLiveSrc, setIsLiveSrc] = useState(false);
  const [liveSource, setLiveSource] = useState(null);
  // const [zoomLevel, setZoomLevel] = useState(false);
  const [goHome, setGoHome] = useState(false);
  const [imageryLayer, setImageryLayer] = useState(null);
  const handleVodOpen = () => setVodOpen(true);
  const handleVodClose = () => setVodOpen(false);
  const handleHlsClose = () => setHlsOpen(false);
  const handleOpen = () => setIsModalOpenCustom(true);
  const metaOpen = () => setIsMetaModalOpen(true);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [toolCategory, setToolCategory] = useState("");
  const [shapeCord, setShapeCord] = useState([]);
  const [shapeCordLatLan, setShapeCordLatLan] = useState([]);
  const [shapeData, setShapeData] = useState({});

  const [allMarkersArray, setAllMarkersArray] = useState([]);

  const [openPolygonAnnotationModal, setOpenPolygonAnnotationModal] =
    useState(false);
  const [openPolylineAnnotationModal, setOpenPolylineAnnotationModal] =
    useState(false);
  const [promptFor, setPromptFor] = useState("");
  const [imageryLabel, setImageryLabel] = useState("2D");

  // redux
  const dispatch = useDispatch();
  const { grpAssets, compareAssets, tileCategory, asset } = useSelector(
    (state) => state.gisHome
  );

  const [mousePosition, setMousePosition] = useState({ lat: 0, lng: 0 });

  const findTimeline = (assetId) => {
    const filteredAssets = grpAssets.filter((asset) => asset.id === assetId);
    if (filteredAssets.length > 0) {
      return getDate(filteredAssets[0].date);
    }
    return null;
  };

  const {
    showComparer,
    annotationTool,
    measureToolToggle,
    polygonAnnotation,
    polylineAnnotation,
  } = useSelector((state) => state.gisTools);

  const {
    markerArray,
    refCounter,
    polygonAnnotationsData,
    polylineAnnotationsData,
    allObservations,
    mlvName,
  } = useSelector((state) => state.gis);

  const defaultCzml = [...getDefaultPoints(grp)];

  const handlePolygonAnnotationModal = () => {
    if (polygonAnnotation === true) {
      setOpenPolygonAnnotationModal(!openPolygonAnnotationModal);
    }
  };

  const handlePolylineAnnotationModal = () => {
    if (polylineAnnotation === true) {
      setOpenPolylineAnnotationModal(!openPolylineAnnotationModal);
    }
  };

  const handleZoomOnChange = () => {
    const viewer = viewerRef.current;
    const camera = viewer.camera;
    viewer.camera.defaultZoomAmount = 0.5;

    camera.zoomOut();
  };

  //zoom control function
  const handleZoom = (direction) => {
    const viewer = viewerRef.current;
    const camera = viewer.camera;
    const maxZoomHeight = 100000;
    const minZoomHeight = 200;
    viewer.camera.defaultZoomAmount = 200;

    const currentHeight = camera.positionCartographic.height;

    if (viewer) {
      if (direction === "in" && currentHeight > minZoomHeight) {
        camera.zoomIn();
      } else if (direction === "out" && currentHeight < maxZoomHeight) {
        camera.zoomOut();
      }
    }
  };

  const handleHlsOpen = (vodLink) => {
    setIsLiveSrc(false);
    setLiveSource(null);
    setSource(vodLink);
    setHlsOpen(true);
  };

  const handleGoLive = (liveSource) => {
    setIsLiveSrc(true);
    setSource(null);
    setLiveSource(liveSource);
    setHlsOpen(true);
  };

  const handleMarkerSave = async (
    assetId,
    type,
    category,
    risk,
    remark,
    file,
    taskStatus,
    addedBy,
    verifiedBy,
    reviewedBy
  ) => {
    if (selectedPosition) {
      const { longitude, latitude } = selectedPosition;
      var imagedata;
      if (file) {
        const imageData = await S3Apis.uploadData(file, "images");
        imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
      }

      const newMarker = {
        id: uuidv4(),
        assetId: assetId, // this is timeline
        timestamp: newTimestamp,
        longitude: longitude,
        latitude: latitude,
        typeName: type,
        category: category,
        severity: risk,
        description: remark,
        markerName: "Observation",
        taskStatus: taskStatus,
        addedBy: addedBy,
        verifiedBy: verifiedBy,
        reviewedBy: reviewedBy,
        createdOn: formatDate(newTimestamp),
        images: imagedata,
        groupName: grp,
        filter_type: "marker",
        visible: true,
        polygon_images: [],
        mlvName: mlvName,
        timeline: assetId,
      };
      AnnotationManager.addAnnotations(newMarker);
      dispatch(gisActions.setMarkerArray([...markerArray, newMarker]));
      handleZoomOnChange();

      // const dataForPdfGeneration = {
      //   annotation_id: newMarker.id,
      //   table_name: "ori_annotations",
      //   annotation_timestamp: newMarker.timestamp,
      //   polygon_cordinates: shapeCordLatLan, //lat_lon walla
      //   timeline: assetId,
      //   type: newMarker.typeName, // for icon type
      //   shape_type: "marker",
      // };

      // const res = await axios.post(
      //   "http://192.168.0.199:5000/create_ori_snapshot_image",
      //   dataForPdfGeneration
      //   // {
      //   //   headers: {
      //   //     "Content-Type": "application/json",
      //   //     "Access-Control-Allow-Origin": "http://localhost:2023/",
      //   //   },
      //   // }
      // );

      // console.log(res, "res");
    }
    setSelectedPosition(null);
  };

  const handleDeleteMarker = (index, selectedMarkerID) => {
    const updatedMarkers = [...markerArray];
    updatedMarkers.splice(index, 1);
    dispatch(gisActions.setMarkerArray(updatedMarkers));
    const timestamp = getTimestamp(selectedMarkerID, allMarkersArray, true);
    AnnotationManager.deleteAnnotations(selectedMarkerID, timestamp);
    const viewer = viewerRef.current;
    viewer.entities.removeById(selectedMarkerID);
    handleZoomOnChange();
  };

  // cesiumContainer initilization
  useEffect(() => {
    try {
      Ion.defaultAccessToken = token;

      // If the viewer doesn't exist, create one
      if (!viewerRef.current) {
        viewerRef.current = new Viewer("cesiumContainer", {
          // viewer defaulf parameters
          requestRenderMode: true,
          maximumRenderTimeChange: Infinity,
          baseLayerPicker: true,
          timeline: false,
          homeButton: false,
          sceneModePicker: false,
          navigationHelpButton: false,
          fullscreenButton: false,
          animation: false,
          creditContainer: null,
        });
      }
    } catch (error) {}
  });

  let flyToFlag = useRef(0);

  useEffect(() => {
    const initCesium = async () => {
      try {
        const viewer = viewerRef.current;
        const baseLayer =
          viewer.imageryLayers.length > 0 ? viewer.imageryLayers.get(0) : null;

        if (baseLayer) {
          viewer.imageryLayers.removeAll();
          viewer.imageryLayers.addImageryProvider(baseLayer.imageryProvider);

          setLoader(true);

          var imageryProvider = new WebMapServiceImageryProvider({
            url: "https://gis.indrones.com/geoserver/pil/wms",
            layers: asset,

            parameters: {
              transparent: "true",
              format: "image/png",
            },
          });

          const imageryLayer =
            viewer.imageryLayers.addImageryProvider(imageryProvider);

          imageryLayer.saturation = 1.3;
          imageryLayer.brightness = 1.1;

          setImageryLayer(imageryLayer);

          flyToFlag.current === 0 &&
            viewer.camera.flyTo({
              destination: Cartesian3.fromDegrees(
                75.05051035457586,
                18.494880619396216,
                40000
              ),
            });

          flyToFlag.current = flyToFlag.current + 1;

          setLoader(false);

          setTimeout(() => {
            setLoader(false);
          }, 1000);
        } else {
          console.error("No imagery layer found.");
          setLoader(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (asset) {
      setLoader(true);
      setTimeout(() => {
        initCesium();
      }, 2000);
    } else if (asset === null && imageryLayer) {
      var imageryLayers = viewerRef.current.imageryLayers;
      imageryLayers.remove(imageryLayer);
    }

    showComparer && dispatch(gisToolActions.toggleTools("showComparer"));
  }, [asset]);

  // show shape
  useGeoJsonOnMap(viewerRef);

  useEffect(() => {
    // effect for showing the lat and lng of cursor positionq
    function locationToolTip() {
      const viewer = viewerRef.current;
      let handler;

      const entity = viewer.entities.add({
        label: {
          show: false,
          showBackground: true,
          font: "14px monospace",
          horizontalOrigin: HorizontalOrigin.LEFT,
          verticalOrigin: VerticalOrigin.TOP,
          pixelOffset: new Cartesian2(15, 0),
          translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
        },
      });

      // Mouse over the globe to see the cartographic position
      handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(function (movement) {
        const cartesian = viewer.camera.pickEllipsoid(
          movement.endPosition,
          viewer.scene.globe.ellipsoid
        );

        if (cartesian) {
          const cartographic = Cartographic.fromCartesian(cartesian);
          const longitudeString = MathOfCesium.toDegrees(
            cartographic.longitude
          ).toFixed(5);
          const latitudeString = MathOfCesium.toDegrees(
            cartographic.latitude
          ).toFixed(5);

          setMousePosition({ lat: latitudeString, lng: longitudeString });
        } else {
          entity.label.show = false;
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
    }
    locationToolTip();
  });

  useEffect(() => {
    const viewer = viewerRef.current;
    viewer.screenSpaceEventHandler.setInputAction((click) => {
      const pickedObject = viewer.scene.pick(click.position);

      if (defined(pickedObject) && pickedObject.id) {
        // Open modal and set selected marker data
        setSelectedMarker({
          id: pickedObject.id.id,
          name: pickedObject.id.name,
          timestamp: pickedObject.id.timestamp,
          description: pickedObject?.id?.description?.getValue(),
          category: pickedObject.id.category,
          mainId: pickedObject.id,
        });

        if (pickedObject.id.id.includes("default_")) {
          setSelectedMarker({});
          setSelectedMarker({
            id: pickedObject.id.id,
            name: pickedObject.id.name,
            description: JSON.parse(pickedObject?.id?.description?.getValue()),
            grpID: `${pickedObject.id.id}_${grp}`,
          });
          handleVodOpen();
        } else {
          if (
            measureToolToggle === false &&
            polygonAnnotation === false &&
            !pickedObject.id.id.includes("default_")
          ) {
            metaOpen();
          } else {
            polygonAnnotation && metaOpen();
          }
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);
  });

  // useCzml(viewerRef);

  useCompareAssets(viewerRef);

  useEffect(() => {
    setAnnotation();
  }, [markerArray]);

  useEffect(() => {
    const viewer = viewerRef.current;
    const getAnno = async () => {
      // setGetPolygonData([]);
      dispatch(gisActions.setPolygonAnnotationsData([]));
      // const response = await DynamoDBApis.getDataByID(asset);

      // dispatch(gisActions.setAllObservations(response));

      const timelineArr = PILDates.map((timeline) => timeline.name);
      const indexOfCurrentTimeline = timelineArr.indexOf(asset);
      console.log(indexOfCurrentTimeline, "indexOfCurrentTimeline");

      const filteredByStatus = allObservations
        .filter(
          (Observation) =>
            Observation.taskStatus !== "Resolved with permanent fix"
        )
        .filter((Observation) =>
          indexOfCurrentTimeline === 0
            ? Observation.timeline === asset
            : Observation
        );

      console.log(filteredByStatus, "filteredByStatus");

      dispatch(gisActions.setFilteredAndInheritedAnnotations(filteredByStatus));

      const polygonObjects = filteredByStatus?.filter(
        (item) => item?.filter_type === "polygon"
      );

      const polylineObjects = filteredByStatus?.filter(
        (item) => item?.filter_type === "polyline"
      );

      const markerObjects = filteredByStatus?.filter(
        (item) => item?.filter_type === "marker"
      );

      setAllMarkersArray(filteredByStatus);
      dispatch(gisActions.setMarkerArray(markerObjects));
      dispatch(gisActions.setPolygonAnnotationsData(polygonObjects));
      dispatch(gisActions.setPolylineAnnotationsData(polylineObjects));

      if (defaultCzml.length > 0) {
        const dataSourcePromise = CzmlDataSource.load(defaultCzml);
        viewer.dataSources.add(dataSourcePromise);
      }
    };

    getAnno();
  }, [allObservations]);

  useEffect(() => {
    const viewer = viewerRef.current;
    const PolygonEntityCollection = new EntityCollection();
    // console.log(PolygonEntityCollection, "PolygonEntityCollection");
    handleZoomOnChange();
    viewer?.entities.removeAll();

    polygonAnnotationsData?.forEach((poly) => {
      console.log(poly, "poly");
      if (poly.shapeCord && poly.shapeCord.length >= 3) {
        var materialColor, outline;
        if (poly.severity === "High") {
          materialColor = Color.RED.withAlpha(0.3);
          outline = Color.RED.withAlpha(0.9);
        } else if (poly.severity === "Medium") {
          materialColor = Color.ORANGE.withAlpha(0.3);
          outline = Color.ORANGE.withAlpha(0.9);
        } else if (poly.severity === "Low") {
          materialColor = Color.YELLOW.withAlpha(0.3);
          outline = Color.YELLOW.withAlpha(0.9);
        } else {
          materialColor = Color.PURPLE.withAlpha(0.3);
          outline = Color.PURPLE.withAlpha(0.3);
        }

        const materialSelector = () => {
          if (poly.assetId !== asset) {
            return new GridMaterialProperty({
              color: outline,
              cellAlpha: 0.3,
              lineCount: new Cartesian2(12, 12),
              lineThickness: new Cartesian2(2.0, 2.0),
            });
          } else {
            return new ColorMaterialProperty(materialColor);
          }
        };

        const positions = poly.shapeCord.map(
          (coord) => new Cartesian3(coord.x, coord.y, coord.z)
        );

        const center = BoundingSphere.fromPoints(positions).center;

        const shape = new Entity({
          id: poly.id,
          name: poly.markerName,
          show: poly?.visible,
          clampToGround: true,

          polygon: {
            hierarchy: positions,
            material: materialSelector(),
          },
          polyline: {
            positions: positions.concat(positions[0]),
            width: 2,
            material: new ColorMaterialProperty(outline),
            granularity: 0.01,
          },

          label: {
            text: `${poly.severity} Risk`,
            fillColor: Color.WHITE,
            showBackground: true,
            font: "14px sans-serif",
            horizontalOrigin: HorizontalOrigin.CENTER,
            verticalOrigin: VerticalOrigin.CENTER,
            // pixelOffset: new Cartesian2(0, -10), // Adjust the offset as needed
            background: new ColorMaterialProperty(Color.BLACK.withAlpha(0.5)), // Adjust the background color and alpha
            backgroundPadding: new Cartesian2(8, 4),
            translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
          },
          position: center,
        });
        if (viewer.entities.getById(poly?.id) === undefined) {
          PolygonEntityCollection.removeAll();
          PolygonEntityCollection.add(shape);
          viewer.entities.add(shape);
        } else {
          if (viewer.entities.getById(poly.id).show !== poly?.show) {
            let latestPoly = viewer.entities?.getById(poly.id);
            latestPoly.show = poly.visible;
          }
        }
      } else {
        console.error(
          `Polygon with id ${poly.id} does not have enough vertices.`
        );
      }
    });
  }, [polygonAnnotationsData]);

  useEffect(() => {
    var shape;
    const viewer = viewerRef.current;
    const PolylineEntityCollection = new EntityCollection();
    // viewer?.entities.removeAll();

    polylineAnnotationsData?.map((poly) => {
      var materialColor, outline;
      if (poly.severity === "High") {
        materialColor = Color.RED.withAlpha(0.9);
        outline = Color.RED.withAlpha(0.9);
      } else if (poly.severity === "Medium") {
        materialColor = Color.ORANGE.withAlpha(0.9);
        outline = Color.ORANGE.withAlpha(0.9);
      } else if (poly.severity === "Low") {
        materialColor = Color.YELLOW.withAlpha(0.9);
        outline = Color.YELLOW.withAlpha(0.9);
      } else {
        materialColor = Color.PURPLE.withAlpha(0.9);
        outline = Color.PURPLE.withAlpha(0.3);
      }
      shape = new Entity({
        id: poly.id,
        name: poly.markerName,
        show: poly?.visible,
        polyline: {
          positions: poly.shapeCord,
          width: 4,
          material: new PolylineOutlineMaterialProperty({
            color: materialColor,
            outlineColor: Color.WHITE,
            outlineWidth: 2,
          }),
          clampToGround: true,
        },
        label: {
          text: `${poly.severity} Risk`,
          fillColor: Color.WHITE,
          showBackground: true,
          font: "14px sans-serif",
          horizontalOrigin: HorizontalOrigin.CENTER,
          verticalOrigin: VerticalOrigin.CENTER,
          pixelOffset: new Cartesian2(0, -10), // Adjust the offset as needed
          background: new ColorMaterialProperty(Color.BLACK.withAlpha(0.5)), // Adjust the background color and alpha
          backgroundPadding: new Cartesian2(8, 4),
          translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
        },
        position: poly.shapeCord[0],
      });
      if (viewer.entities.getById(poly?.id) === undefined) {
        PolylineEntityCollection.removeAll();
        PolylineEntityCollection.add(shape);
        viewer.entities.add(shape);
      } else {
        if (viewer.entities.getById(poly.id).show !== poly?.show) {
          let latestPoly = viewer.entities?.getById(poly.id);
          latestPoly.show = poly.visible;
        }
      }
    });
  }, [polylineAnnotationsData]);

  useEffect(() => {
    const viewer = viewerRef.current;
    // viewer?.entities.removeAll();
    const MarkerEntityCollection = new EntityCollection();

    markerArray?.forEach((marker) => {
      if (marker.visible) {
        const markerImg = (marker) => {
          let materialColor, icon;
          if (marker.severity === "High" && marker.typeName === "Tree") {
            materialColor = Color.RED.withAlpha(0.3);
            icon = treeIconHigh;
          } else if (
            marker.severity === "Medium" &&
            marker.typeName === "Building"
          ) {
            materialColor = Color.ORANGE.withAlpha(0.3);
            icon = buildingIconHigh;
          } else if (marker.severity === "Medium") {
            materialColor = Color.ORANGE.withAlpha(0.3);
            icon = buildingIconMedium;
          } else if (marker.severity === "Low") {
            materialColor = Color.YELLOW.withAlpha(0.3);
            icon = buildingIconLow;
          } else {
            materialColor = Color.PURPLE.withAlpha(0.3);
            icon = customBillboardImg;
          }
          return { materialColor, icon };
        };
        const markerEntity = new Entity({
          id: marker.id,
          name: marker.markerName,
          // show: marker?.visible,
          clampToGround: true,
          position: Cartesian3.fromDegrees(marker.longitude, marker.latitude),
          billboard: {
            image: markerImg(marker).icon,
            verticalOrigin: VerticalOrigin.BOTTOM,
            width: 40,
            height: 40,
            scaleByDistance: new NearFarScalar(35.5e2, 1, 90.0e2, 0.0),
          },
          // translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
        });

        if (viewer.entities.getById(marker?.id) === undefined) {
          MarkerEntityCollection.removeAll();
          MarkerEntityCollection.add(markerEntity);
          viewer.entities.add(markerEntity);
        } else {
          if (viewer.entities.getById(marker.id).show !== marker?.show) {
            let latestPoly = viewer.entities?.getById(marker.id);
            latestPoly.show = marker.visible;
          }
        }
      } else {
        console.log(`Marker with id ${marker.id} is not visible.`);
      }
    });

    return () => {
      viewer?.entities.removeAll();
    };
  }, [markerArray]);

  usePolygonAnnotationTool(
    viewerRef,
    setShapeCord,
    setShapeData,
    handlePolygonAnnotationModal,
    setShapeCordLatLan
  );

  usePolylineAnnotationTool(
    viewerRef,
    setShapeCord,
    setShapeData,
    handlePolylineAnnotationModal,
    setShapeCordLatLan
  );

  usePointMarkerAnnotation(viewerRef, setSelectedPosition, handleOpen);
  usePolylineMeasureTool(viewerRef, setShapeCord, setShapeData);

  usePolygonMeasureTool(viewerRef, setShapeCord);

  useLineMeasureTool(viewerRef, setShapeCord, setShapeData);

  // mouse down on chevron
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      // Only activate on left mouse button down (button 0)
      setIsClicked(true);
    }
  };

  const handleMouseUp = () => {
    setIsClicked(false);
  };

  const chevronOuterBox = `h-12 w-12 relative right-5 rounded-full top-[47%] border-2 border-[#d9d9d9] flex justify-evenly items-center flex-row-reverse ${
    isClicked ? "bg-gray-200" : ""
  }`;

  return (
    <>
      <div className="h-full w-full" id="cesiumContainer">
        <div id="tooltip" className="none"></div>
        <GISToolbar
          handleHomeButtonClick={() =>
            handleHomeButtonClick(viewerRef, setGoHome, goHome, imageryLayer)
          }
          handleZoom={handleZoom}
          imageryLabel={imageryLabel}
          cursorLatLng={mousePosition}
        />
        {showComparer === true ? (
          <div id="slider" className="relative">
            <h2
              className={`${
                compareAssets?.[0] &&
                `m-0 w-max font-semibold text-center absolute top-1 right-5 bg-gray-100 p-1 rounded-md border-2`
              } `}
            >
              {tileCategory === "GEOSERVER"
                ? compareAssets[0]
                : findTimeline(compareAssets?.[0])}
            </h2>
            <h2
              className={`${
                compareAssets?.[1] &&
                `m-0 w-max font-semibold text-center absolute top-1 left-5 bg-gray-100 p-1 rounded-md border-2`
              } `}
            >
              {tileCategory === "GEOSERVER"
                ? compareAssets[1]
                : findTimeline(compareAssets?.[1])}
            </h2>

            <div
              className={chevronOuterBox}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <button
                id="right-chevron"
                className=" rounded-full h-6 w-6 flex items-center justify-center"
              >
                {/* Right chevron */}
                <img src={chevronIco} className="h-max w-max " alt="" />
              </button>
              {/* Rest of your JSX */}
              <button
                id="left-chevron"
                className=" h-6 w-6 rounded-full flex items-center justify-center"
              >
                {/* Right chevron */}
                <img
                  src={chevronIco}
                  className="h-max w-max transform scaleX(-1) rotate-180"
                  alt=""
                />
              </button>
            </div>
          </div>
        ) : null}
        {promptFor === "polyline" ? (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Polyline Measure Tool : </strong> Add points using left
            click and complete the polyline using right click.
          </p>
        ) : (
          promptFor === "polygon" && (
            <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
              <strong>Polygon Measure Tool : </strong> Add points using left
              click and complete the polygon using right click.
            </p>
          )
        )}
        {polygonAnnotation && (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Polygon Marker Tool : </strong> Add points using left click
            and complete the polyline using right click.
          </p>
        )}
        {polylineAnnotation && (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Polyline Marker Tool : </strong> Add points using left click
            and complete the polygon using right click.
          </p>
        )}

        {annotationTool && (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Point Marker Tool : </strong> Right click to add marker.
          </p>
        )}
      </div>
      {annotationTool && isModalOpenCustom && (
        <MetaInput
          isOpen={isModalOpenCustom}
          onClose={() => {
            setIsModalOpenCustom(false);
          }}
          onSave={handleMarkerSave}
        />
      )}
      {openPolygonAnnotationModal && measureToolToggle === false && (
        <PolygonAnnotationModal
          isOpen={openPolygonAnnotationModal}
          onClose={() => setOpenPolygonAnnotationModal(false)}
          shapeData={shapeData}
          shapeCord={shapeCord}
          grp={grp}
          shapeCordLatLan={shapeCordLatLan}
        />
      )}
      {openPolylineAnnotationModal && measureToolToggle === false && (
        <PolylineAnnotationModal
          isOpen={openPolylineAnnotationModal}
          onClose={() => setOpenPolylineAnnotationModal(false)}
          shapeData={shapeData}
          shapeCord={shapeCord}
          shapeCordLatLan={shapeCordLatLan}
        />
      )}
      {measureToolToggle === false &&
        polygonAnnotation === false &&
        polylineAnnotation === false &&
        selectedMarker?.name !== undefined && (
          <>
            <MetaDataModal
              isOpen={isMetaModalOpen}
              onClose={() => setIsMetaModalOpen(false)}
              name={selectedMarker?.name}
              id={selectedMarker?.id}
              markerData={getTimestamp(selectedMarker?.id, allMarkersArray)}
              // descriptions={
              //   getTimestamp(selectedMarker?.id, allMarkersArray)?.description
              // }
              // Category={
              //   getTimestamp(selectedMarker?.id, allMarkersArray)?.category
              // }
              // Severity={
              //   getTimestamp(selectedMarker?.id, allMarkersArray)?.severity
              // }
              images={getTimestamp(selectedMarker?.id, allMarkersArray)?.images}
              viewer={viewerRef.current}
              // fetchData={fetchData}
              onDeleteMarker={() =>
                handleDeleteMarker(
                  selectedMarker?.mainId?.id,
                  selectedMarker?.id
                )
              }
              // refCounterUpdate={refCounterUpdate}
            />
          </>
        )}

      {measureToolToggle === false &&
        polygonAnnotation === false &&
        selectedMarker?.name !== undefined && (
          <>
            <VodBox
              selectedMarker={selectedMarker}
              handleGoLive={handleGoLive}
              vodOpen={vodOpen}
              handleVodOpen={handleVodOpen}
              handleVodClose={handleVodClose}
              handleHlsOpen={handleHlsOpen}
            />
            <VideoModal
              liveSource={liveSource}
              isLiveSrc={isLiveSrc}
              src={source}
              hlsOpen={hlsOpen}
              handleHlsClose={handleHlsClose}
              selectedMarker={selectedMarker?.id}
            />
          </>
        )}
    </>
  );
};

export default GIS;
