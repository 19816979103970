import "./App.css";
import "leaflet/dist/leaflet.css";
import "../src/Assets/styles/pageStyle.scss";
import { Route, Routes } from "react-router-dom";
import {
  ServicePage,
  Login,
  Signup,
  Projects,
  ProjectViewLayout,
  Assets,
  SingleScreen,
  CompareScreen,
} from "./imports";
import GISHome from "./Pages/GIS/GISHome";
import Thankyou from "./Pages/Home/Thankyou";
import PipelineInfraLogin from "./Pages/Login/PipelineInfraLogin";
// import Admin from "./Components/Admin/Admin";

function App() {
  return (
    <div className="App h-full overflowhidden">
      <Routes>
        {/* <Route path="/" element={<ServicePage />}></Route> */}
        <Route path="/" element={<PipelineInfraLogin />}></Route>

        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route element={<ProjectViewLayout />}>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/assets" element={<Assets />}></Route>
        </Route>
        <Route path="gis/:grp" element={<GISHome />}></Route>
        <Route path="surveillance/:project" element={<SingleScreen />}></Route>
        <Route path="compare/:ids" element={<CompareScreen />}></Route>
        <Route path="/nhai" element={<Thankyou />} />
      </Routes>
    </div>
  );
}

export default App;
