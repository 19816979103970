export const pilObservetionsRisks = ["Low", "Medium", "High"];
export const pilObservations = [
  "Building",
  "Excavation Area",
  "HT Line",
  "MLV Station",
  "Pylon",
  "Structure ",
  "Tree",
  "Other",
];
export const pilObservationCategories = [
  "Encroachment",
  "Pipeline Exposure",
  "Pipeline Washout",
];

export const pilTaskStatus = [
  "Yet to start",
  "Started",
  "On Hold",
  "Legal action Initiated",
  "Resolved with permanent fix",
  "Resolved with temporary fix",
];

export const CSStationsList = [
  {
    name: "CS01",
    stationName: "Kakinada",
    state: "AP",
    latitude: 16.7465,
    longitude: 82.2995,
  },
  {
    name: "CS02",
    stationName: "Eluru",
    state: "AP",
    latitude: 16.7531,
    longitude: 81.0127,
  },
  {
    name: "CS03",
    stationName: "Suryapet",
    state: "TS",
    latitude: 17.1122,
    longitude: 79.7643,
  },
  {
    name: "CS04",
    stationName: "Pudur",
    state: "TS",
    latitude: 17.6132,
    longitude: 78.5347,
  },
  {
    name: "CS05",
    stationName: "Humnabad",
    state: "KA",
    latitude: 17.8191,
    longitude: 77.1709,
  },
  {
    name: "CS06",
    stationName: "Barshi",
    state: "MH",
    latitude: 18.261,
    longitude: 75.7486,
  },
  {
    name: "CS07",
    stationName: "Shirur",
    state: "MH",
    latitude: 18.6367,
    longitude: 74.3815,
  },
  {
    name: "CS08",
    stationName: "Kalyan",
    state: "MH",
    latitude: 19.2467,
    longitude: 73.2493,
  },
  {
    name: "CS09",
    stationName: "Valsad",
    state: "GJ",
    latitude: 20.5837,
    longitude: 73.0242,
  },
  {
    name: "CS10",
    stationName: "Bharuch",
    state: "GJ",
    latitude: 21.679,
    longitude: 72.8293,
  },
];

export const PILDates = [
  { name: "PIL_22_23_APRIL", timeline: "2023/04/22", id: 0 },
  { name: "PIL_22_23_JUNE", timeline: "2023/06/22", id: 1 },
];
